export const exercisesDataCache = [
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0001.gif",
        "id": "0001",
        "name": "3/4 sit-up",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0002.gif",
        "id": "0002",
        "name": "45° side bend",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0003.gif",
        "id": "0003",
        "name": "air bike",
        "target": "abs"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1512.gif",
        "id": "1512",
        "name": "all fours squad stretch",
        "target": "quads"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0006.gif",
        "id": "0006",
        "name": "alternate heel touchers",
        "target": "abs"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0007.gif",
        "id": "0007",
        "name": "alternate lateral pulldown",
        "target": "lats"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1368.gif",
        "id": "1368",
        "name": "ankle circles",
        "target": "calves"
    },
    {
        "bodyPart": "back",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3293.gif",
        "id": "3293",
        "name": "archer pull up",
        "target": "lats"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3294.gif",
        "id": "3294",
        "name": "archer push up",
        "target": "pectorals"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/2355.gif",
        "id": "2355",
        "name": "arm slingers hanging bent knee legs",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/2333.gif",
        "id": "2333",
        "name": "arm slingers hanging straight legs",
        "target": "abs"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3214.gif",
        "id": "3214",
        "name": "arms apart circular toe touch (male)",
        "target": "glutes"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3204.gif",
        "id": "3204",
        "name": "arms overhead full sit-up (male)",
        "target": "abs"
    },
    {
        "bodyPart": "chest",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/0009.gif",
        "id": "0009",
        "name": "assisted chest dip (kneeling)",
        "target": "pectorals"
    },
    {
        "bodyPart": "waist",
        "equipment": "assisted",
        "gifUrl": "/assets/gifs/0011.gif",
        "id": "0011",
        "name": "assisted hanging knee raise",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "assisted",
        "gifUrl": "/assets/gifs/0010.gif",
        "id": "0010",
        "name": "assisted hanging knee raise with throw down",
        "target": "abs"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "assisted",
        "gifUrl": "/assets/gifs/1708.gif",
        "id": "1708",
        "name": "assisted lying calves stretch",
        "target": "calves"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "assisted",
        "gifUrl": "/assets/gifs/1709.gif",
        "id": "1709",
        "name": "assisted lying glutes stretch",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "assisted",
        "gifUrl": "/assets/gifs/1710.gif",
        "id": "1710",
        "name": "assisted lying gluteus and piriformis stretch",
        "target": "glutes"
    },
    {
        "bodyPart": "waist",
        "equipment": "assisted",
        "gifUrl": "/assets/gifs/0012.gif",
        "id": "0012",
        "name": "assisted lying leg raise with lateral throw down",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "assisted",
        "gifUrl": "/assets/gifs/0013.gif",
        "id": "0013",
        "name": "assisted lying leg raise with throw down",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "medicine ball",
        "gifUrl": "/assets/gifs/0014.gif",
        "id": "0014",
        "name": "assisted motion russian twist",
        "target": "abs"
    },
    {
        "bodyPart": "back",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/0015.gif",
        "id": "0015",
        "name": "assisted parallel close grip pull-up",
        "target": "lats"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "assisted",
        "gifUrl": "/assets/gifs/0016.gif",
        "id": "0016",
        "name": "assisted prone hamstring",
        "target": "hamstrings"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "assisted",
        "gifUrl": "/assets/gifs/1713.gif",
        "id": "1713",
        "name": "assisted prone lying quads stretch",
        "target": "quads"
    },
    {
        "bodyPart": "waist",
        "equipment": "assisted",
        "gifUrl": "/assets/gifs/1714.gif",
        "id": "1714",
        "name": "assisted prone rectus femoris stretch",
        "target": "abs"
    },
    {
        "bodyPart": "back",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/0017.gif",
        "id": "0017",
        "name": "assisted pull-up",
        "target": "lats"
    },
    {
        "bodyPart": "chest",
        "equipment": "assisted",
        "gifUrl": "/assets/gifs/1716.gif",
        "id": "1716",
        "name": "assisted seated pectoralis major stretch with stability ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "assisted",
        "gifUrl": "/assets/gifs/1712.gif",
        "id": "1712",
        "name": "assisted side lying adductor stretch",
        "target": "adductors"
    },
    {
        "bodyPart": "waist",
        "equipment": "assisted",
        "gifUrl": "/assets/gifs/1758.gif",
        "id": "1758",
        "name": "assisted sit-up",
        "target": "abs"
    },
    {
        "bodyPart": "back",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/1431.gif",
        "id": "1431",
        "name": "assisted standing chin-up",
        "target": "lats"
    },
    {
        "bodyPart": "back",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/1432.gif",
        "id": "1432",
        "name": "assisted standing pull-up",
        "target": "lats"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "assisted",
        "gifUrl": "/assets/gifs/0018.gif",
        "id": "0018",
        "name": "assisted standing triceps extension (with towel)",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/0019.gif",
        "id": "0019",
        "name": "assisted triceps dip (kneeling)",
        "target": "triceps"
    },
    {
        "bodyPart": "chest",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/2364.gif",
        "id": "2364",
        "name": "assisted wide-grip chest dip (kneeling)",
        "target": "pectorals"
    },
    {
        "bodyPart": "cardio",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3220.gif",
        "id": "3220",
        "name": "astride jumps (male)",
        "target": "cardiovascular system"
    },
    {
        "bodyPart": "cardio",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3672.gif",
        "id": "3672",
        "name": "back and forth step",
        "target": "cardiovascular system"
    },
    {
        "bodyPart": "back",
        "equipment": "stability ball",
        "gifUrl": "/assets/gifs/1314.gif",
        "id": "1314",
        "name": "back extension on exercise ball",
        "target": "spine"
    },
    {
        "bodyPart": "back",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3297.gif",
        "id": "3297",
        "name": "back lever",
        "target": "upper back"
    },
    {
        "bodyPart": "back",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1405.gif",
        "id": "1405",
        "name": "back pec stretch",
        "target": "lats"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1473.gif",
        "id": "1473",
        "name": "backward jump",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0020.gif",
        "id": "0020",
        "name": "balance board",
        "target": "quads"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "band",
        "gifUrl": "/assets/gifs/0968.gif",
        "id": "0968",
        "name": "band alternating biceps curl",
        "target": "biceps"
    },
    {
        "bodyPart": "waist",
        "equipment": "band",
        "gifUrl": "/assets/gifs/0969.gif",
        "id": "0969",
        "name": "band alternating v-up",
        "target": "abs"
    },
    {
        "bodyPart": "back",
        "equipment": "band",
        "gifUrl": "/assets/gifs/0970.gif",
        "id": "0970",
        "name": "band assisted pull-up",
        "target": "lats"
    },
    {
        "bodyPart": "waist",
        "equipment": "band",
        "gifUrl": "/assets/gifs/0971.gif",
        "id": "0971",
        "name": "band assisted wheel rollerout",
        "target": "abs"
    },
    {
        "bodyPart": "chest",
        "equipment": "band",
        "gifUrl": "/assets/gifs/1254.gif",
        "id": "1254",
        "name": "band bench press",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "band",
        "gifUrl": "/assets/gifs/0980.gif",
        "id": "0980",
        "name": "band bent-over hip extension",
        "target": "glutes"
    },
    {
        "bodyPart": "waist",
        "equipment": "band",
        "gifUrl": "/assets/gifs/0972.gif",
        "id": "0972",
        "name": "band bicycle crunch",
        "target": "abs"
    },
    {
        "bodyPart": "back",
        "equipment": "band",
        "gifUrl": "/assets/gifs/0974.gif",
        "id": "0974",
        "name": "band close-grip pulldown",
        "target": "lats"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "band",
        "gifUrl": "/assets/gifs/0975.gif",
        "id": "0975",
        "name": "band close-grip push-up",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "band",
        "gifUrl": "/assets/gifs/0976.gif",
        "id": "0976",
        "name": "band concentration curl",
        "target": "biceps"
    },
    {
        "bodyPart": "back",
        "equipment": "band",
        "gifUrl": "/assets/gifs/3117.gif",
        "id": "3117",
        "name": "band fixed back close grip pulldown",
        "target": "lats"
    },
    {
        "bodyPart": "back",
        "equipment": "band",
        "gifUrl": "/assets/gifs/3116.gif",
        "id": "3116",
        "name": "band fixed back underhand pulldown",
        "target": "lats"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "band",
        "gifUrl": "/assets/gifs/0977.gif",
        "id": "0977",
        "name": "band front lateral raise",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "band",
        "gifUrl": "/assets/gifs/0978.gif",
        "id": "0978",
        "name": "band front raise",
        "target": "delts"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "band",
        "gifUrl": "/assets/gifs/1408.gif",
        "id": "1408",
        "name": "band hip lift",
        "target": "glutes"
    },
    {
        "bodyPart": "waist",
        "equipment": "band",
        "gifUrl": "/assets/gifs/0979.gif",
        "id": "0979",
        "name": "band horizontal pallof press",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "band",
        "gifUrl": "/assets/gifs/0981.gif",
        "id": "0981",
        "name": "band jack knife sit-up",
        "target": "abs"
    },
    {
        "bodyPart": "back",
        "equipment": "band",
        "gifUrl": "/assets/gifs/0983.gif",
        "id": "0983",
        "name": "band kneeling one arm pulldown",
        "target": "lats"
    },
    {
        "bodyPart": "waist",
        "equipment": "band",
        "gifUrl": "/assets/gifs/0985.gif",
        "id": "0985",
        "name": "band kneeling twisting crunch",
        "target": "abs"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "band",
        "gifUrl": "/assets/gifs/0984.gif",
        "id": "0984",
        "name": "band lying hip internal rotation",
        "target": "glutes"
    },
    {
        "bodyPart": "waist",
        "equipment": "band",
        "gifUrl": "/assets/gifs/1002.gif",
        "id": "1002",
        "name": "band lying straight leg raise",
        "target": "abs"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "band",
        "gifUrl": "/assets/gifs/0986.gif",
        "id": "0986",
        "name": "band one arm overhead biceps curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "band",
        "gifUrl": "/assets/gifs/0987.gif",
        "id": "0987",
        "name": "band one arm single leg split squat",
        "target": "quads"
    },
    {
        "bodyPart": "back",
        "equipment": "band",
        "gifUrl": "/assets/gifs/0988.gif",
        "id": "0988",
        "name": "band one arm standing low row",
        "target": "upper back"
    },
    {
        "bodyPart": "chest",
        "equipment": "band",
        "gifUrl": "/assets/gifs/0989.gif",
        "id": "0989",
        "name": "band one arm twisting chest press",
        "target": "pectorals"
    },
    {
        "bodyPart": "back",
        "equipment": "band",
        "gifUrl": "/assets/gifs/0990.gif",
        "id": "0990",
        "name": "band one arm twisting seated row",
        "target": "upper back"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "band",
        "gifUrl": "/assets/gifs/0991.gif",
        "id": "0991",
        "name": "band pull through",
        "target": "glutes"
    },
    {
        "bodyPart": "waist",
        "equipment": "band",
        "gifUrl": "/assets/gifs/0992.gif",
        "id": "0992",
        "name": "band push sit-up",
        "target": "abs"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "band",
        "gifUrl": "/assets/gifs/0993.gif",
        "id": "0993",
        "name": "band reverse fly",
        "target": "delts"
    },
    {
        "bodyPart": "lower arms",
        "equipment": "band",
        "gifUrl": "/assets/gifs/0994.gif",
        "id": "0994",
        "name": "band reverse wrist curl",
        "target": "forearms"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "band",
        "gifUrl": "/assets/gifs/0996.gif",
        "id": "0996",
        "name": "band seated hip internal rotation",
        "target": "glutes"
    },
    {
        "bodyPart": "waist",
        "equipment": "band",
        "gifUrl": "/assets/gifs/1011.gif",
        "id": "1011",
        "name": "band seated twist",
        "target": "abs"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "band",
        "gifUrl": "/assets/gifs/0997.gif",
        "id": "0997",
        "name": "band shoulder press",
        "target": "delts"
    },
    {
        "bodyPart": "back",
        "equipment": "band",
        "gifUrl": "/assets/gifs/1018.gif",
        "id": "1018",
        "name": "band shrug",
        "target": "traps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "band",
        "gifUrl": "/assets/gifs/0998.gif",
        "id": "0998",
        "name": "band side triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "band",
        "gifUrl": "/assets/gifs/0999.gif",
        "id": "0999",
        "name": "band single leg calf raise",
        "target": "calves"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "band",
        "gifUrl": "/assets/gifs/1000.gif",
        "id": "1000",
        "name": "band single leg reverse calf raise",
        "target": "calves"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "band",
        "gifUrl": "/assets/gifs/1001.gif",
        "id": "1001",
        "name": "band single leg split squat",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "band",
        "gifUrl": "/assets/gifs/1004.gif",
        "id": "1004",
        "name": "band squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "band",
        "gifUrl": "/assets/gifs/1003.gif",
        "id": "1003",
        "name": "band squat row",
        "target": "glutes"
    },
    {
        "bodyPart": "waist",
        "equipment": "band",
        "gifUrl": "/assets/gifs/1005.gif",
        "id": "1005",
        "name": "band standing crunch",
        "target": "abs"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "band",
        "gifUrl": "/assets/gifs/1022.gif",
        "id": "1022",
        "name": "band standing rear delt row",
        "target": "delts"
    },
    {
        "bodyPart": "waist",
        "equipment": "band",
        "gifUrl": "/assets/gifs/1007.gif",
        "id": "1007",
        "name": "band standing twisting crunch",
        "target": "abs"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "band",
        "gifUrl": "/assets/gifs/1008.gif",
        "id": "1008",
        "name": "band step-up",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "band",
        "gifUrl": "/assets/gifs/1009.gif",
        "id": "1009",
        "name": "band stiff leg deadlift",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "band",
        "gifUrl": "/assets/gifs/1023.gif",
        "id": "1023",
        "name": "band straight back stiff leg deadlift",
        "target": "glutes"
    },
    {
        "bodyPart": "back",
        "equipment": "band",
        "gifUrl": "/assets/gifs/1010.gif",
        "id": "1010",
        "name": "band straight leg deadlift",
        "target": "spine"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "band",
        "gifUrl": "/assets/gifs/1012.gif",
        "id": "1012",
        "name": "band twisting overhead press",
        "target": "delts"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "band",
        "gifUrl": "/assets/gifs/1369.gif",
        "id": "1369",
        "name": "band two legs calf raise - (band under both legs) v. 2",
        "target": "calves"
    },
    {
        "bodyPart": "back",
        "equipment": "band",
        "gifUrl": "/assets/gifs/1013.gif",
        "id": "1013",
        "name": "band underhand pulldown",
        "target": "lats"
    },
    {
        "bodyPart": "waist",
        "equipment": "band",
        "gifUrl": "/assets/gifs/1014.gif",
        "id": "1014",
        "name": "band v-up",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "band",
        "gifUrl": "/assets/gifs/1015.gif",
        "id": "1015",
        "name": "band vertical pallof press",
        "target": "abs"
    },
    {
        "bodyPart": "lower arms",
        "equipment": "band",
        "gifUrl": "/assets/gifs/1016.gif",
        "id": "1016",
        "name": "band wrist curl",
        "target": "forearms"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "band",
        "gifUrl": "/assets/gifs/1017.gif",
        "id": "1017",
        "name": "band y-raise",
        "target": "delts"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0023.gif",
        "id": "0023",
        "name": "barbell alternate biceps curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0024.gif",
        "id": "0024",
        "name": "barbell bench front squat",
        "target": "quads"
    },
    {
        "bodyPart": "chest",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0025.gif",
        "id": "0025",
        "name": "barbell bench press",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0026.gif",
        "id": "0026",
        "name": "barbell bench squat",
        "target": "quads"
    },
    {
        "bodyPart": "back",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/1316.gif",
        "id": "1316",
        "name": "barbell bent arm pullover",
        "target": "lats"
    },
    {
        "bodyPart": "back",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0027.gif",
        "id": "0027",
        "name": "barbell bent over row",
        "target": "upper back"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/2407.gif",
        "id": "2407",
        "name": "barbell biceps curl (with arm blaster)",
        "target": "biceps"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0028.gif",
        "id": "0028",
        "name": "barbell clean and press",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0029.gif",
        "id": "0029",
        "name": "barbell clean-grip front squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0030.gif",
        "id": "0030",
        "name": "barbell close-grip bench press",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0031.gif",
        "id": "0031",
        "name": "barbell curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0032.gif",
        "id": "0032",
        "name": "barbell deadlift",
        "target": "glutes"
    },
    {
        "bodyPart": "chest",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0033.gif",
        "id": "0033",
        "name": "barbell decline bench press",
        "target": "pectorals"
    },
    {
        "bodyPart": "back",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0034.gif",
        "id": "0034",
        "name": "barbell decline bent arm pullover",
        "target": "lats"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0035.gif",
        "id": "0035",
        "name": "barbell decline close grip to skull press",
        "target": "triceps"
    },
    {
        "bodyPart": "chest",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/1255.gif",
        "id": "1255",
        "name": "barbell decline pullover",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0036.gif",
        "id": "0036",
        "name": "barbell decline wide-grip press",
        "target": "pectorals"
    },
    {
        "bodyPart": "back",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0037.gif",
        "id": "0037",
        "name": "barbell decline wide-grip pullover",
        "target": "lats"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0038.gif",
        "id": "0038",
        "name": "barbell drag curl",
        "target": "biceps"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/1370.gif",
        "id": "1370",
        "name": "barbell floor calf raise",
        "target": "calves"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0039.gif",
        "id": "0039",
        "name": "barbell front chest squat",
        "target": "glutes"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0041.gif",
        "id": "0041",
        "name": "barbell front raise",
        "target": "delts"
    },
    {
        "bodyPart": "chest",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0040.gif",
        "id": "0040",
        "name": "barbell front raise and pullover",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0042.gif",
        "id": "0042",
        "name": "barbell front squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0043.gif",
        "id": "0043",
        "name": "barbell full squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/1461.gif",
        "id": "1461",
        "name": "barbell full squat (back pov)",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/1462.gif",
        "id": "1462",
        "name": "barbell full squat (side pov)",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/1545.gif",
        "id": "1545",
        "name": "barbell full zercher squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/1409.gif",
        "id": "1409",
        "name": "barbell glute bridge",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/3562.gif",
        "id": "3562",
        "name": "barbell glute bridge two legs on bench (male)",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0044.gif",
        "id": "0044",
        "name": "barbell good morning",
        "target": "hamstrings"
    },
    {
        "bodyPart": "chest",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0045.gif",
        "id": "0045",
        "name": "barbell guillotine bench press",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0046.gif",
        "id": "0046",
        "name": "barbell hack squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/1436.gif",
        "id": "1436",
        "name": "barbell high bar squat",
        "target": "glutes"
    },
    {
        "bodyPart": "chest",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0047.gif",
        "id": "0047",
        "name": "barbell incline bench press",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/1719.gif",
        "id": "1719",
        "name": "barbell incline close grip bench press",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0048.gif",
        "id": "0048",
        "name": "barbell incline reverse-grip press",
        "target": "triceps"
    },
    {
        "bodyPart": "back",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0049.gif",
        "id": "0049",
        "name": "barbell incline row",
        "target": "upper back"
    },
    {
        "bodyPart": "chest",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0050.gif",
        "id": "0050",
        "name": "barbell incline shoulder raise",
        "target": "serratus anterior"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0051.gif",
        "id": "0051",
        "name": "barbell jefferson squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0052.gif",
        "id": "0052",
        "name": "barbell jm bench press",
        "target": "triceps"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0053.gif",
        "id": "0053",
        "name": "barbell jump squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/1410.gif",
        "id": "1410",
        "name": "barbell lateral lunge",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/1435.gif",
        "id": "1435",
        "name": "barbell low bar squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0054.gif",
        "id": "0054",
        "name": "barbell lunge",
        "target": "glutes"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/1720.gif",
        "id": "1720",
        "name": "barbell lying back of the head tricep extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0055.gif",
        "id": "0055",
        "name": "barbell lying close-grip press",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0056.gif",
        "id": "0056",
        "name": "barbell lying close-grip triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0057.gif",
        "id": "0057",
        "name": "barbell lying extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0058.gif",
        "id": "0058",
        "name": "barbell lying lifting (on hip)",
        "target": "glutes"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0059.gif",
        "id": "0059",
        "name": "barbell lying preacher curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0061.gif",
        "id": "0061",
        "name": "barbell lying triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0060.gif",
        "id": "0060",
        "name": "barbell lying triceps extension skull crusher",
        "target": "triceps"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0063.gif",
        "id": "0063",
        "name": "barbell narrow stance squat",
        "target": "glutes"
    },
    {
        "bodyPart": "back",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0064.gif",
        "id": "0064",
        "name": "barbell one arm bent over row",
        "target": "upper back"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0065.gif",
        "id": "0065",
        "name": "barbell one arm floor press",
        "target": "triceps"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0066.gif",
        "id": "0066",
        "name": "barbell one arm side deadlift",
        "target": "glutes"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0067.gif",
        "id": "0067",
        "name": "barbell one arm snatch",
        "target": "delts"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0068.gif",
        "id": "0068",
        "name": "barbell one leg squat",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0069.gif",
        "id": "0069",
        "name": "barbell overhead squat",
        "target": "quads"
    },
    {
        "bodyPart": "lower arms",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/1411.gif",
        "id": "1411",
        "name": "barbell palms down wrist curl over a bench",
        "target": "forearms"
    },
    {
        "bodyPart": "lower arms",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/1412.gif",
        "id": "1412",
        "name": "barbell palms up wrist curl over a bench",
        "target": "forearms"
    },
    {
        "bodyPart": "back",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/3017.gif",
        "id": "3017",
        "name": "barbell pendlay row",
        "target": "upper back"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/1751.gif",
        "id": "1751",
        "name": "barbell pin presses",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0070.gif",
        "id": "0070",
        "name": "barbell preacher curl",
        "target": "biceps"
    },
    {
        "bodyPart": "waist",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0071.gif",
        "id": "0071",
        "name": "barbell press sit-up",
        "target": "abs"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0072.gif",
        "id": "0072",
        "name": "barbell prone incline curl",
        "target": "biceps"
    },
    {
        "bodyPart": "back",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0073.gif",
        "id": "0073",
        "name": "barbell pullover",
        "target": "lats"
    },
    {
        "bodyPart": "back",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0022.gif",
        "id": "0022",
        "name": "barbell pullover to press",
        "target": "lats"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0074.gif",
        "id": "0074",
        "name": "barbell rack pull",
        "target": "glutes"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0075.gif",
        "id": "0075",
        "name": "barbell rear delt raise",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0076.gif",
        "id": "0076",
        "name": "barbell rear delt row",
        "target": "delts"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0078.gif",
        "id": "0078",
        "name": "barbell rear lunge",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0077.gif",
        "id": "0077",
        "name": "barbell rear lunge v. 2",
        "target": "glutes"
    },
    {
        "bodyPart": "lower arms",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0079.gif",
        "id": "0079",
        "name": "barbell revers wrist curl v. 2",
        "target": "forearms"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/2187.gif",
        "id": "2187",
        "name": "barbell reverse close-grip bench press",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0080.gif",
        "id": "0080",
        "name": "barbell reverse curl",
        "target": "biceps"
    },
    {
        "bodyPart": "back",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0118.gif",
        "id": "0118",
        "name": "barbell reverse grip bent over row",
        "target": "upper back"
    },
    {
        "bodyPart": "chest",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/1256.gif",
        "id": "1256",
        "name": "barbell reverse grip decline bench press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/1257.gif",
        "id": "1257",
        "name": "barbell reverse grip incline bench press",
        "target": "pectorals"
    },
    {
        "bodyPart": "back",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/1317.gif",
        "id": "1317",
        "name": "barbell reverse grip incline bench row",
        "target": "upper back"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/1721.gif",
        "id": "1721",
        "name": "barbell reverse grip skullcrusher",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0081.gif",
        "id": "0081",
        "name": "barbell reverse preacher curl",
        "target": "biceps"
    },
    {
        "bodyPart": "lower arms",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0082.gif",
        "id": "0082",
        "name": "barbell reverse wrist curl",
        "target": "forearms"
    },
    {
        "bodyPart": "waist",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0084.gif",
        "id": "0084",
        "name": "barbell rollerout",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0083.gif",
        "id": "0083",
        "name": "barbell rollerout from bench",
        "target": "abs"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0085.gif",
        "id": "0085",
        "name": "barbell romanian deadlift",
        "target": "glutes"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0086.gif",
        "id": "0086",
        "name": "barbell seated behind head military press",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0087.gif",
        "id": "0087",
        "name": "barbell seated bradford rocky press",
        "target": "delts"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0088.gif",
        "id": "0088",
        "name": "barbell seated calf raise",
        "target": "calves"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/1371.gif",
        "id": "1371",
        "name": "barbell seated calf raise",
        "target": "calves"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/1718.gif",
        "id": "1718",
        "name": "barbell seated close grip behind neck triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0089.gif",
        "id": "0089",
        "name": "barbell seated close-grip concentration curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0090.gif",
        "id": "0090",
        "name": "barbell seated good morning",
        "target": "glutes"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0091.gif",
        "id": "0091",
        "name": "barbell seated overhead press",
        "target": "delts"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0092.gif",
        "id": "0092",
        "name": "barbell seated overhead triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "waist",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0094.gif",
        "id": "0094",
        "name": "barbell seated twist",
        "target": "abs"
    },
    {
        "bodyPart": "back",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0095.gif",
        "id": "0095",
        "name": "barbell shrug",
        "target": "traps"
    },
    {
        "bodyPart": "waist",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0096.gif",
        "id": "0096",
        "name": "barbell side bent v. 2",
        "target": "abs"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0098.gif",
        "id": "0098",
        "name": "barbell side split squat",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0097.gif",
        "id": "0097",
        "name": "barbell side split squat v. 2",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/1756.gif",
        "id": "1756",
        "name": "barbell single leg deadlift",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0099.gif",
        "id": "0099",
        "name": "barbell single leg split squat",
        "target": "quads"
    },
    {
        "bodyPart": "waist",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/2799.gif",
        "id": "2799",
        "name": "barbell sitted alternate leg raise",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/2800.gif",
        "id": "2800",
        "name": "barbell sitted alternate leg raise (female)",
        "target": "abs"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0100.gif",
        "id": "0100",
        "name": "barbell skier",
        "target": "delts"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0101.gif",
        "id": "0101",
        "name": "barbell speed squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/2810.gif",
        "id": "2810",
        "name": "barbell split squat v. 2",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0102.gif",
        "id": "0102",
        "name": "barbell squat (on knees)",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/2798.gif",
        "id": "2798",
        "name": "barbell squat jump step rear lunge",
        "target": "quads"
    },
    {
        "bodyPart": "waist",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0103.gif",
        "id": "0103",
        "name": "barbell standing ab rollerout",
        "target": "abs"
    },
    {
        "bodyPart": "lower arms",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0104.gif",
        "id": "0104",
        "name": "barbell standing back wrist curl",
        "target": "forearms"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0105.gif",
        "id": "0105",
        "name": "barbell standing bradford press",
        "target": "delts"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/1372.gif",
        "id": "1372",
        "name": "barbell standing calf raise",
        "target": "calves"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0106.gif",
        "id": "0106",
        "name": "barbell standing close grip curl",
        "target": "biceps"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/1456.gif",
        "id": "1456",
        "name": "barbell standing close grip military press",
        "target": "delts"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/2414.gif",
        "id": "2414",
        "name": "barbell standing concentration curl",
        "target": "biceps"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0107.gif",
        "id": "0107",
        "name": "barbell standing front raise over head",
        "target": "delts"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0108.gif",
        "id": "0108",
        "name": "barbell standing leg calf raise",
        "target": "calves"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0109.gif",
        "id": "0109",
        "name": "barbell standing overhead triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0110.gif",
        "id": "0110",
        "name": "barbell standing reverse grip curl",
        "target": "biceps"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0111.gif",
        "id": "0111",
        "name": "barbell standing rocking leg calf raise",
        "target": "calves"
    },
    {
        "bodyPart": "waist",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0112.gif",
        "id": "0112",
        "name": "barbell standing twist",
        "target": "abs"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/1629.gif",
        "id": "1629",
        "name": "barbell standing wide grip biceps curl",
        "target": "biceps"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/1457.gif",
        "id": "1457",
        "name": "barbell standing wide military press",
        "target": "delts"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0113.gif",
        "id": "0113",
        "name": "barbell standing wide-grip curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0114.gif",
        "id": "0114",
        "name": "barbell step-up",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0115.gif",
        "id": "0115",
        "name": "barbell stiff leg good morning",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0116.gif",
        "id": "0116",
        "name": "barbell straight leg deadlift",
        "target": "hamstrings"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0117.gif",
        "id": "0117",
        "name": "barbell sumo deadlift",
        "target": "glutes"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/3305.gif",
        "id": "3305",
        "name": "barbell thruster",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0120.gif",
        "id": "0120",
        "name": "barbell upright row",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0119.gif",
        "id": "0119",
        "name": "barbell upright row v. 2",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0121.gif",
        "id": "0121",
        "name": "barbell upright row v. 3",
        "target": "delts"
    },
    {
        "bodyPart": "chest",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0122.gif",
        "id": "0122",
        "name": "barbell wide bench press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/1258.gif",
        "id": "1258",
        "name": "barbell wide reverse grip bench press",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0124.gif",
        "id": "0124",
        "name": "barbell wide squat",
        "target": "quads"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0123.gif",
        "id": "0123",
        "name": "barbell wide-grip upright row",
        "target": "delts"
    },
    {
        "bodyPart": "lower arms",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0126.gif",
        "id": "0126",
        "name": "barbell wrist curl",
        "target": "forearms"
    },
    {
        "bodyPart": "lower arms",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0125.gif",
        "id": "0125",
        "name": "barbell wrist curl v. 2",
        "target": "forearms"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0127.gif",
        "id": "0127",
        "name": "barbell zercher squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3212.gif",
        "id": "3212",
        "name": "basic toe touch (male)",
        "target": "glutes"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "rope",
        "gifUrl": "/assets/gifs/0128.gif",
        "id": "0128",
        "name": "battling ropes",
        "target": "delts"
    },
    {
        "bodyPart": "cardio",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3360.gif",
        "id": "3360",
        "name": "bear crawl",
        "target": "cardiovascular system"
    },
    {
        "bodyPart": "chest",
        "equipment": "assisted",
        "gifUrl": "/assets/gifs/1259.gif",
        "id": "1259",
        "name": "behind head chest stretch",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0129.gif",
        "id": "0129",
        "name": "bench dip (knees bent)",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1399.gif",
        "id": "1399",
        "name": "bench dip on floor",
        "target": "triceps"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0130.gif",
        "id": "0130",
        "name": "bench hip extension",
        "target": "glutes"
    },
    {
        "bodyPart": "back",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3019.gif",
        "id": "3019",
        "name": "bench pull-ups",
        "target": "lats"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3639.gif",
        "id": "3639",
        "name": "bent knee lying twist (male)",
        "target": "glutes"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1770.gif",
        "id": "1770",
        "name": "biceps leg concentration curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0139.gif",
        "id": "0139",
        "name": "biceps narrow pull-ups",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0140.gif",
        "id": "0140",
        "name": "biceps pull-up",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0137.gif",
        "id": "0137",
        "name": "body-up",
        "target": "triceps"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3543.gif",
        "id": "3543",
        "name": "bodyweight drop jump squat",
        "target": "glutes"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3544.gif",
        "id": "3544",
        "name": "bodyweight incline side plank",
        "target": "abs"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1771.gif",
        "id": "1771",
        "name": "bodyweight kneeling triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1769.gif",
        "id": "1769",
        "name": "bodyweight side lying biceps curl",
        "target": "biceps"
    },
    {
        "bodyPart": "back",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3168.gif",
        "id": "3168",
        "name": "bodyweight squatting row",
        "target": "upper back"
    },
    {
        "bodyPart": "back",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3167.gif",
        "id": "3167",
        "name": "bodyweight squatting row (with towel)",
        "target": "upper back"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1373.gif",
        "id": "1373",
        "name": "bodyweight standing calf raise",
        "target": "calves"
    },
    {
        "bodyPart": "back",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3156.gif",
        "id": "3156",
        "name": "bodyweight standing close-grip one arm row",
        "target": "upper back"
    },
    {
        "bodyPart": "back",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3158.gif",
        "id": "3158",
        "name": "bodyweight standing close-grip row",
        "target": "upper back"
    },
    {
        "bodyPart": "back",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3162.gif",
        "id": "3162",
        "name": "bodyweight standing one arm row",
        "target": "upper back"
    },
    {
        "bodyPart": "back",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3161.gif",
        "id": "3161",
        "name": "bodyweight standing one arm row (with towel)",
        "target": "upper back"
    },
    {
        "bodyPart": "back",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3166.gif",
        "id": "3166",
        "name": "bodyweight standing row",
        "target": "upper back"
    },
    {
        "bodyPart": "back",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3165.gif",
        "id": "3165",
        "name": "bodyweight standing row (with towel)",
        "target": "upper back"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0138.gif",
        "id": "0138",
        "name": "bottoms-up",
        "target": "abs"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1374.gif",
        "id": "1374",
        "name": "box jump down with one leg stabilization",
        "target": "calves"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/2466.gif",
        "id": "2466",
        "name": "bridge - mountain climber (cross body)",
        "target": "abs"
    },
    {
        "bodyPart": "cardio",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1160.gif",
        "id": "1160",
        "name": "burpee",
        "target": "cardiovascular system"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0870.gif",
        "id": "0870",
        "name": "butt-ups",
        "target": "abs"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1494.gif",
        "id": "1494",
        "name": "butterfly yoga pose",
        "target": "adductors"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0148.gif",
        "id": "0148",
        "name": "cable alternate shoulder press",
        "target": "delts"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0149.gif",
        "id": "0149",
        "name": "cable alternate triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/3235.gif",
        "id": "3235",
        "name": "cable assisted inverse leg curl",
        "target": "hamstrings"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0150.gif",
        "id": "0150",
        "name": "cable bar lateral pulldown",
        "target": "lats"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0151.gif",
        "id": "0151",
        "name": "cable bench press",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/1630.gif",
        "id": "1630",
        "name": "cable close grip curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/1631.gif",
        "id": "1631",
        "name": "cable concentration curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0152.gif",
        "id": "0152",
        "name": "cable concentration extension (on knee)",
        "target": "triceps"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0153.gif",
        "id": "0153",
        "name": "cable cross-over lateral pulldown",
        "target": "lats"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0154.gif",
        "id": "0154",
        "name": "cable cross-over revers fly",
        "target": "delts"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0155.gif",
        "id": "0155",
        "name": "cable cross-over variation",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0868.gif",
        "id": "0868",
        "name": "cable curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0157.gif",
        "id": "0157",
        "name": "cable deadlift",
        "target": "glutes"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0158.gif",
        "id": "0158",
        "name": "cable decline fly",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/1260.gif",
        "id": "1260",
        "name": "cable decline one arm press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/1261.gif",
        "id": "1261",
        "name": "cable decline press",
        "target": "pectorals"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0159.gif",
        "id": "0159",
        "name": "cable decline seated wide-grip row",
        "target": "upper back"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/1632.gif",
        "id": "1632",
        "name": "cable drag curl",
        "target": "biceps"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0160.gif",
        "id": "0160",
        "name": "cable floor seated wide-grip row",
        "target": "upper back"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0161.gif",
        "id": "0161",
        "name": "cable forward raise",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0162.gif",
        "id": "0162",
        "name": "cable front raise",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0164.gif",
        "id": "0164",
        "name": "cable front shoulder raise",
        "target": "delts"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0165.gif",
        "id": "0165",
        "name": "cable hammer curl (with rope)",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/1722.gif",
        "id": "1722",
        "name": "cable high pulley overhead tricep extension",
        "target": "triceps"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0167.gif",
        "id": "0167",
        "name": "cable high row (kneeling)",
        "target": "upper back"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0168.gif",
        "id": "0168",
        "name": "cable hip adduction",
        "target": "adductors"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0169.gif",
        "id": "0169",
        "name": "cable incline bench press",
        "target": "pectorals"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/1318.gif",
        "id": "1318",
        "name": "cable incline bench row",
        "target": "upper back"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0171.gif",
        "id": "0171",
        "name": "cable incline fly",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0170.gif",
        "id": "0170",
        "name": "cable incline fly (on stability ball)",
        "target": "pectorals"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0172.gif",
        "id": "0172",
        "name": "cable incline pushdown",
        "target": "lats"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0173.gif",
        "id": "0173",
        "name": "cable incline triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "waist",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0174.gif",
        "id": "0174",
        "name": "cable judo flip",
        "target": "abs"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0860.gif",
        "id": "0860",
        "name": "cable kickback",
        "target": "triceps"
    },
    {
        "bodyPart": "waist",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0175.gif",
        "id": "0175",
        "name": "cable kneeling crunch",
        "target": "abs"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/3697.gif",
        "id": "3697",
        "name": "cable kneeling rear delt row (with rope) (male)",
        "target": "delts"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0176.gif",
        "id": "0176",
        "name": "cable kneeling triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/2330.gif",
        "id": "2330",
        "name": "cable lat pulldown full range of motion",
        "target": "lats"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0177.gif",
        "id": "0177",
        "name": "cable lateral pulldown (with rope attachment)",
        "target": "lats"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/2616.gif",
        "id": "2616",
        "name": "cable lateral pulldown with v-bar",
        "target": "lats"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0178.gif",
        "id": "0178",
        "name": "cable lateral raise",
        "target": "delts"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0179.gif",
        "id": "0179",
        "name": "cable low fly",
        "target": "pectorals"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0180.gif",
        "id": "0180",
        "name": "cable low seated row",
        "target": "upper back"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/1634.gif",
        "id": "1634",
        "name": "cable lying bicep curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0182.gif",
        "id": "0182",
        "name": "cable lying close-grip curl",
        "target": "biceps"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0184.gif",
        "id": "0184",
        "name": "cable lying extension pullover (with rope attachment)",
        "target": "lats"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0185.gif",
        "id": "0185",
        "name": "cable lying fly",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0186.gif",
        "id": "0186",
        "name": "cable lying triceps extension v. 2",
        "target": "triceps"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0188.gif",
        "id": "0188",
        "name": "cable middle fly",
        "target": "pectorals"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0189.gif",
        "id": "0189",
        "name": "cable one arm bent over row",
        "target": "upper back"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0190.gif",
        "id": "0190",
        "name": "cable one arm curl",
        "target": "biceps"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/1262.gif",
        "id": "1262",
        "name": "cable one arm decline chest fly",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/1263.gif",
        "id": "1263",
        "name": "cable one arm fly on exercise ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/1264.gif",
        "id": "1264",
        "name": "cable one arm incline fly on exercise ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/1265.gif",
        "id": "1265",
        "name": "cable one arm incline press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/1266.gif",
        "id": "1266",
        "name": "cable one arm incline press on exercise ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0191.gif",
        "id": "0191",
        "name": "cable one arm lateral bent-over",
        "target": "pectorals"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0192.gif",
        "id": "0192",
        "name": "cable one arm lateral raise",
        "target": "delts"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/1633.gif",
        "id": "1633",
        "name": "cable one arm preacher curl",
        "target": "biceps"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/1267.gif",
        "id": "1267",
        "name": "cable one arm press on exercise ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/3563.gif",
        "id": "3563",
        "name": "cable one arm pulldown",
        "target": "lats"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/1635.gif",
        "id": "1635",
        "name": "cable one arm reverse preacher curl",
        "target": "biceps"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0193.gif",
        "id": "0193",
        "name": "cable one arm straight back high row (kneeling)",
        "target": "upper back"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/1723.gif",
        "id": "1723",
        "name": "cable one arm tricep pushdown",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/1636.gif",
        "id": "1636",
        "name": "cable overhead curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/1637.gif",
        "id": "1637",
        "name": "cable overhead curl on exercise ball",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0194.gif",
        "id": "0194",
        "name": "cable overhead triceps extension (rope attachment)",
        "target": "triceps"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/1319.gif",
        "id": "1319",
        "name": "cable palm rotational row",
        "target": "upper back"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0195.gif",
        "id": "0195",
        "name": "cable preacher curl",
        "target": "biceps"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/1268.gif",
        "id": "1268",
        "name": "cable press on exercise ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0196.gif",
        "id": "0196",
        "name": "cable pull through (with rope)",
        "target": "glutes"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0198.gif",
        "id": "0198",
        "name": "cable pulldown",
        "target": "lats"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0197.gif",
        "id": "0197",
        "name": "cable pulldown (pro lat bar)",
        "target": "lats"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/1638.gif",
        "id": "1638",
        "name": "cable pulldown bicep curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0201.gif",
        "id": "0201",
        "name": "cable pushdown",
        "target": "triceps"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0199.gif",
        "id": "0199",
        "name": "cable pushdown (straight arm) v. 2",
        "target": "lats"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0200.gif",
        "id": "0200",
        "name": "cable pushdown (with rope attachment)",
        "target": "triceps"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0202.gif",
        "id": "0202",
        "name": "cable rear delt row (stirrups)",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0203.gif",
        "id": "0203",
        "name": "cable rear delt row (with rope)",
        "target": "delts"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0204.gif",
        "id": "0204",
        "name": "cable rear drive",
        "target": "triceps"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0205.gif",
        "id": "0205",
        "name": "cable rear pulldown",
        "target": "lats"
    },
    {
        "bodyPart": "waist",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0873.gif",
        "id": "0873",
        "name": "cable reverse crunch",
        "target": "abs"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0206.gif",
        "id": "0206",
        "name": "cable reverse curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/2406.gif",
        "id": "2406",
        "name": "cable reverse grip triceps pushdown (sz-bar) (with arm blaster)",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/1413.gif",
        "id": "1413",
        "name": "cable reverse one arm curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0209.gif",
        "id": "0209",
        "name": "cable reverse preacher curl",
        "target": "biceps"
    },
    {
        "bodyPart": "lower arms",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0210.gif",
        "id": "0210",
        "name": "cable reverse wrist curl",
        "target": "forearms"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0207.gif",
        "id": "0207",
        "name": "cable reverse-grip pushdown",
        "target": "triceps"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0208.gif",
        "id": "0208",
        "name": "cable reverse-grip straight back seated high row",
        "target": "upper back"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/1320.gif",
        "id": "1320",
        "name": "cable rope crossover seated row",
        "target": "upper back"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/1321.gif",
        "id": "1321",
        "name": "cable rope elevated seated row",
        "target": "upper back"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/1322.gif",
        "id": "1322",
        "name": "cable rope extension incline bench row",
        "target": "upper back"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/1639.gif",
        "id": "1639",
        "name": "cable rope hammer preacher curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/1724.gif",
        "id": "1724",
        "name": "cable rope high pulley overhead tricep extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/1725.gif",
        "id": "1725",
        "name": "cable rope incline tricep extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/1726.gif",
        "id": "1726",
        "name": "cable rope lying on floor tricep extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/1640.gif",
        "id": "1640",
        "name": "cable rope one arm hammer preacher curl",
        "target": "biceps"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/1323.gif",
        "id": "1323",
        "name": "cable rope seated row",
        "target": "upper back"
    },
    {
        "bodyPart": "waist",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0211.gif",
        "id": "0211",
        "name": "cable russian twists (on stability ball)",
        "target": "abs"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/2144.gif",
        "id": "2144",
        "name": "cable seated chest press",
        "target": "pectorals"
    },
    {
        "bodyPart": "waist",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0212.gif",
        "id": "0212",
        "name": "cable seated crunch",
        "target": "abs"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/1641.gif",
        "id": "1641",
        "name": "cable seated curl",
        "target": "biceps"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0213.gif",
        "id": "0213",
        "name": "cable seated high row (v-bar)",
        "target": "lats"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0214.gif",
        "id": "0214",
        "name": "cable seated one arm alternate row",
        "target": "upper back"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/1642.gif",
        "id": "1642",
        "name": "cable seated one arm concentration curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/1643.gif",
        "id": "1643",
        "name": "cable seated overhead curl",
        "target": "biceps"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0215.gif",
        "id": "0215",
        "name": "cable seated rear lateral raise",
        "target": "delts"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0861.gif",
        "id": "0861",
        "name": "cable seated row",
        "target": "upper back"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0216.gif",
        "id": "0216",
        "name": "cable seated shoulder internal rotation",
        "target": "delts"
    },
    {
        "bodyPart": "waist",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/2399.gif",
        "id": "2399",
        "name": "cable seated twist",
        "target": "abs"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0218.gif",
        "id": "0218",
        "name": "cable seated wide-grip row",
        "target": "upper back"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0219.gif",
        "id": "0219",
        "name": "cable shoulder press",
        "target": "delts"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0220.gif",
        "id": "0220",
        "name": "cable shrug",
        "target": "traps"
    },
    {
        "bodyPart": "waist",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0222.gif",
        "id": "0222",
        "name": "cable side bend",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0221.gif",
        "id": "0221",
        "name": "cable side bend crunch (bosu ball)",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0223.gif",
        "id": "0223",
        "name": "cable side crunch",
        "target": "abs"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/1717.gif",
        "id": "1717",
        "name": "cable squat row (with rope attachment)",
        "target": "lats"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/1644.gif",
        "id": "1644",
        "name": "cable squatting curl",
        "target": "biceps"
    },
    {
        "bodyPart": "lower arms",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0224.gif",
        "id": "0224",
        "name": "cable standing back wrist curl",
        "target": "forearms"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/1375.gif",
        "id": "1375",
        "name": "cable standing calf raise",
        "target": "calves"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0225.gif",
        "id": "0225",
        "name": "cable standing cross-over high reverse fly",
        "target": "delts"
    },
    {
        "bodyPart": "waist",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0226.gif",
        "id": "0226",
        "name": "cable standing crunch",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0874.gif",
        "id": "0874",
        "name": "cable standing crunch (with rope attachment)",
        "target": "abs"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0227.gif",
        "id": "0227",
        "name": "cable standing fly",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0228.gif",
        "id": "0228",
        "name": "cable standing hip extension",
        "target": "glutes"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0229.gif",
        "id": "0229",
        "name": "cable standing inner curl",
        "target": "biceps"
    },
    {
        "bodyPart": "waist",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0230.gif",
        "id": "0230",
        "name": "cable standing lift",
        "target": "abs"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0231.gif",
        "id": "0231",
        "name": "cable standing one arm triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/1376.gif",
        "id": "1376",
        "name": "cable standing one leg calf raise",
        "target": "calves"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0232.gif",
        "id": "0232",
        "name": "cable standing pulldown (with rope)",
        "target": "biceps"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0233.gif",
        "id": "0233",
        "name": "cable standing rear delt row (with rope)",
        "target": "delts"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/1727.gif",
        "id": "1727",
        "name": "cable standing reverse grip one arm overhead tricep extension",
        "target": "triceps"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0234.gif",
        "id": "0234",
        "name": "cable standing row (v-bar)",
        "target": "upper back"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0235.gif",
        "id": "0235",
        "name": "cable standing shoulder external rotation",
        "target": "delts"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0236.gif",
        "id": "0236",
        "name": "cable standing twist row (v-bar)",
        "target": "upper back"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/1269.gif",
        "id": "1269",
        "name": "cable standing up straight crossovers",
        "target": "pectorals"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0238.gif",
        "id": "0238",
        "name": "cable straight arm pulldown",
        "target": "lats"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0237.gif",
        "id": "0237",
        "name": "cable straight arm pulldown (with rope)",
        "target": "lats"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0239.gif",
        "id": "0239",
        "name": "cable straight back seated row",
        "target": "upper back"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0240.gif",
        "id": "0240",
        "name": "cable supine reverse fly",
        "target": "delts"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/2464.gif",
        "id": "2464",
        "name": "cable thibaudeau kayak row",
        "target": "lats"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0241.gif",
        "id": "0241",
        "name": "cable triceps pushdown (v-bar)",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/2405.gif",
        "id": "2405",
        "name": "cable triceps pushdown (v-bar) (with arm blaster)",
        "target": "triceps"
    },
    {
        "bodyPart": "waist",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0242.gif",
        "id": "0242",
        "name": "cable tuck reverse crunch",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0243.gif",
        "id": "0243",
        "name": "cable twist",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0862.gif",
        "id": "0862",
        "name": "cable twist (up-down)",
        "target": "abs"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0244.gif",
        "id": "0244",
        "name": "cable twisting pull",
        "target": "lats"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/1645.gif",
        "id": "1645",
        "name": "cable two arm curl on incline bench",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/1728.gif",
        "id": "1728",
        "name": "cable two arm tricep kickback",
        "target": "triceps"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0245.gif",
        "id": "0245",
        "name": "cable underhand pulldown",
        "target": "lats"
    },
    {
        "bodyPart": "chest",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/1270.gif",
        "id": "1270",
        "name": "cable upper chest crossovers",
        "target": "pectorals"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/1324.gif",
        "id": "1324",
        "name": "cable upper row",
        "target": "upper back"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0246.gif",
        "id": "0246",
        "name": "cable upright row",
        "target": "delts"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/1325.gif",
        "id": "1325",
        "name": "cable wide grip rear pulldown behind neck",
        "target": "lats"
    },
    {
        "bodyPart": "lower arms",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0247.gif",
        "id": "0247",
        "name": "cable wrist curl",
        "target": "forearms"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1407.gif",
        "id": "1407",
        "name": "calf push stretch with hands against wall",
        "target": "calves"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1377.gif",
        "id": "1377",
        "name": "calf stretch with hands against wall",
        "target": "calves"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "rope",
        "gifUrl": "/assets/gifs/1378.gif",
        "id": "1378",
        "name": "calf stretch with rope",
        "target": "calves"
    },
    {
        "bodyPart": "back",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0248.gif",
        "id": "0248",
        "name": "cambered bar lying row",
        "target": "upper back"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/2963.gif",
        "id": "2963",
        "name": "captains chair straight leg raise",
        "target": "abs"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1548.gif",
        "id": "1548",
        "name": "chair leg extended stretch",
        "target": "quads"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1271.gif",
        "id": "1271",
        "name": "chest and front of shoulder stretch",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0251.gif",
        "id": "0251",
        "name": "chest dip",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1430.gif",
        "id": "1430",
        "name": "chest dip (on dip-pull-up cage)",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/2462.gif",
        "id": "2462",
        "name": "chest dip on straight bar",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "stability ball",
        "gifUrl": "/assets/gifs/1272.gif",
        "id": "1272",
        "name": "chest stretch with exercise ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3216.gif",
        "id": "3216",
        "name": "chest tap push-up (male)",
        "target": "pectorals"
    },
    {
        "bodyPart": "back",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1326.gif",
        "id": "1326",
        "name": "chin-up",
        "target": "lats"
    },
    {
        "bodyPart": "back",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0253.gif",
        "id": "0253",
        "name": "chin-ups (narrow parallel grip)",
        "target": "upper back"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0257.gif",
        "id": "0257",
        "name": "circles knee stretch",
        "target": "calves"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1273.gif",
        "id": "1273",
        "name": "clap push up",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0258.gif",
        "id": "0258",
        "name": "clock push-up",
        "target": "pectorals"
    },
    {
        "bodyPart": "back",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1327.gif",
        "id": "1327",
        "name": "close grip chin-up",
        "target": "lats"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0259.gif",
        "id": "0259",
        "name": "close-grip push-up",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/2398.gif",
        "id": "2398",
        "name": "close-grip push-up (on knees)",
        "target": "triceps"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0260.gif",
        "id": "0260",
        "name": "cocoons",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1468.gif",
        "id": "1468",
        "name": "crab twist toe touch",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0262.gif",
        "id": "0262",
        "name": "cross body crunch",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0267.gif",
        "id": "0267",
        "name": "crunch (hands overhead)",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "stability ball",
        "gifUrl": "/assets/gifs/0271.gif",
        "id": "0271",
        "name": "crunch (on stability ball)",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "stability ball",
        "gifUrl": "/assets/gifs/0272.gif",
        "id": "0272",
        "name": "crunch (on stability ball, arms straight)",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0274.gif",
        "id": "0274",
        "name": "crunch floor",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3016.gif",
        "id": "3016",
        "name": "curl-up",
        "target": "abs"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3769.gif",
        "id": "3769",
        "name": "curtsey squat",
        "target": "glutes"
    },
    {
        "bodyPart": "cardio",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/2331.gif",
        "id": "2331",
        "name": "cycle cross trainer",
        "target": "cardiovascular system"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0276.gif",
        "id": "0276",
        "name": "dead bug",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0277.gif",
        "id": "0277",
        "name": "decline crunch",
        "target": "abs"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0279.gif",
        "id": "0279",
        "name": "decline push-up",
        "target": "pectorals"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0282.gif",
        "id": "0282",
        "name": "decline sit-up",
        "target": "abs"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1274.gif",
        "id": "1274",
        "name": "deep push up",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0283.gif",
        "id": "0283",
        "name": "diamond push-up",
        "target": "triceps"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0284.gif",
        "id": "0284",
        "name": "donkey calf raise",
        "target": "calves"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1275.gif",
        "id": "1275",
        "name": "drop push up",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0285.gif",
        "id": "0285",
        "name": "dumbbell alternate biceps curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/2403.gif",
        "id": "2403",
        "name": "dumbbell alternate biceps curl (with arm blaster)",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1646.gif",
        "id": "1646",
        "name": "dumbbell alternate hammer preacher curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1647.gif",
        "id": "1647",
        "name": "dumbbell alternate preacher curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1648.gif",
        "id": "1648",
        "name": "dumbbell alternate seated hammer curl",
        "target": "biceps"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0286.gif",
        "id": "0286",
        "name": "dumbbell alternate side press",
        "target": "delts"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1649.gif",
        "id": "1649",
        "name": "dumbbell alternating bicep curl with leg raised on exercise ball",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1650.gif",
        "id": "1650",
        "name": "dumbbell alternating seated bicep curl on exercise ball",
        "target": "biceps"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/2137.gif",
        "id": "2137",
        "name": "dumbbell arnold press",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0287.gif",
        "id": "0287",
        "name": "dumbbell arnold press v. 2",
        "target": "delts"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0288.gif",
        "id": "0288",
        "name": "dumbbell around pullover",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0289.gif",
        "id": "0289",
        "name": "dumbbell bench press",
        "target": "pectorals"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0290.gif",
        "id": "0290",
        "name": "dumbbell bench seated press",
        "target": "delts"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0291.gif",
        "id": "0291",
        "name": "dumbbell bench squat",
        "target": "glutes"
    },
    {
        "bodyPart": "back",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0293.gif",
        "id": "0293",
        "name": "dumbbell bent over row",
        "target": "upper back"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1651.gif",
        "id": "1651",
        "name": "dumbbell bicep curl lunge with bowling motion",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1652.gif",
        "id": "1652",
        "name": "dumbbell bicep curl on exercise ball with leg raised",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1653.gif",
        "id": "1653",
        "name": "dumbbell bicep curl with stork stance",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0294.gif",
        "id": "0294",
        "name": "dumbbell biceps curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/2401.gif",
        "id": "2401",
        "name": "dumbbell biceps curl (with arm blaster)",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1654.gif",
        "id": "1654",
        "name": "dumbbell biceps curl reverse",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1655.gif",
        "id": "1655",
        "name": "dumbbell biceps curl squat",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1656.gif",
        "id": "1656",
        "name": "dumbbell biceps curl v sit on bosu ball",
        "target": "biceps"
    },
    {
        "bodyPart": "cardio",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1201.gif",
        "id": "1201",
        "name": "dumbbell burpee",
        "target": "cardiovascular system"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0295.gif",
        "id": "0295",
        "name": "dumbbell clean",
        "target": "glutes"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1731.gif",
        "id": "1731",
        "name": "dumbbell close grip press",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0296.gif",
        "id": "0296",
        "name": "dumbbell close-grip press",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0297.gif",
        "id": "0297",
        "name": "dumbbell concentration curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/3635.gif",
        "id": "3635",
        "name": "dumbbell contralateral forward lunge",
        "target": "glutes"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0298.gif",
        "id": "0298",
        "name": "dumbbell cross body hammer curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1657.gif",
        "id": "1657",
        "name": "dumbbell cross body hammer curl v. 2",
        "target": "biceps"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0299.gif",
        "id": "0299",
        "name": "dumbbell cuban press",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/2136.gif",
        "id": "2136",
        "name": "dumbbell cuban press v. 2",
        "target": "delts"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0300.gif",
        "id": "0300",
        "name": "dumbbell deadlift",
        "target": "glutes"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0301.gif",
        "id": "0301",
        "name": "dumbbell decline bench press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0302.gif",
        "id": "0302",
        "name": "dumbbell decline fly",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0303.gif",
        "id": "0303",
        "name": "dumbbell decline hammer press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1276.gif",
        "id": "1276",
        "name": "dumbbell decline one arm fly",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1617.gif",
        "id": "1617",
        "name": "dumbbell decline one arm hammer press",
        "target": "triceps"
    },
    {
        "bodyPart": "back",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0305.gif",
        "id": "0305",
        "name": "dumbbell decline shrug",
        "target": "traps"
    },
    {
        "bodyPart": "back",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0304.gif",
        "id": "0304",
        "name": "dumbbell decline shrug v. 2",
        "target": "traps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0306.gif",
        "id": "0306",
        "name": "dumbbell decline triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0307.gif",
        "id": "0307",
        "name": "dumbbell decline twist fly",
        "target": "pectorals"
    },
    {
        "bodyPart": "lower arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1437.gif",
        "id": "1437",
        "name": "dumbbell finger curls",
        "target": "forearms"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0308.gif",
        "id": "0308",
        "name": "dumbbell fly",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1277.gif",
        "id": "1277",
        "name": "dumbbell fly on exercise ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1732.gif",
        "id": "1732",
        "name": "dumbbell forward lunge triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0310.gif",
        "id": "0310",
        "name": "dumbbell front raise",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0309.gif",
        "id": "0309",
        "name": "dumbbell front raise v. 2",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0311.gif",
        "id": "0311",
        "name": "dumbbell full can lateral raise",
        "target": "delts"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1760.gif",
        "id": "1760",
        "name": "dumbbell goblet squat",
        "target": "quads"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0313.gif",
        "id": "0313",
        "name": "dumbbell hammer curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1659.gif",
        "id": "1659",
        "name": "dumbbell hammer curl on exercise ball",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0312.gif",
        "id": "0312",
        "name": "dumbbell hammer curl v. 2",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/2402.gif",
        "id": "2402",
        "name": "dumbbell hammer curls (with arm blaster)",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1664.gif",
        "id": "1664",
        "name": "dumbbell high curl",
        "target": "biceps"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/3545.gif",
        "id": "3545",
        "name": "dumbbell incline alternate press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0314.gif",
        "id": "0314",
        "name": "dumbbell incline bench press",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0315.gif",
        "id": "0315",
        "name": "dumbbell incline biceps curl",
        "target": "biceps"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0316.gif",
        "id": "0316",
        "name": "dumbbell incline breeding",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0318.gif",
        "id": "0318",
        "name": "dumbbell incline curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0317.gif",
        "id": "0317",
        "name": "dumbbell incline curl v. 2",
        "target": "biceps"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0319.gif",
        "id": "0319",
        "name": "dumbbell incline fly",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1278.gif",
        "id": "1278",
        "name": "dumbbell incline fly on exercise ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0320.gif",
        "id": "0320",
        "name": "dumbbell incline hammer curl",
        "target": "biceps"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0321.gif",
        "id": "0321",
        "name": "dumbbell incline hammer press",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1618.gif",
        "id": "1618",
        "name": "dumbbell incline hammer press on exercise ball",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0322.gif",
        "id": "0322",
        "name": "dumbbell incline inner biceps curl",
        "target": "biceps"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1279.gif",
        "id": "1279",
        "name": "dumbbell incline one arm fly",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1280.gif",
        "id": "1280",
        "name": "dumbbell incline one arm fly on exercise ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1619.gif",
        "id": "1619",
        "name": "dumbbell incline one arm hammer press",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1620.gif",
        "id": "1620",
        "name": "dumbbell incline one arm hammer press on exercise ball",
        "target": "triceps"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0323.gif",
        "id": "0323",
        "name": "dumbbell incline one arm lateral raise",
        "target": "delts"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1281.gif",
        "id": "1281",
        "name": "dumbbell incline one arm press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1282.gif",
        "id": "1282",
        "name": "dumbbell incline one arm press on exercise ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0324.gif",
        "id": "0324",
        "name": "dumbbell incline palm-in press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1283.gif",
        "id": "1283",
        "name": "dumbbell incline press on exercise ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0325.gif",
        "id": "0325",
        "name": "dumbbell incline raise",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0326.gif",
        "id": "0326",
        "name": "dumbbell incline rear lateral raise",
        "target": "delts"
    },
    {
        "bodyPart": "back",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0327.gif",
        "id": "0327",
        "name": "dumbbell incline row",
        "target": "upper back"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0328.gif",
        "id": "0328",
        "name": "dumbbell incline shoulder raise",
        "target": "serratus anterior"
    },
    {
        "bodyPart": "back",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0329.gif",
        "id": "0329",
        "name": "dumbbell incline shrug",
        "target": "traps"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/3542.gif",
        "id": "3542",
        "name": "dumbbell incline t-raise",
        "target": "delts"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0330.gif",
        "id": "0330",
        "name": "dumbbell incline triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0331.gif",
        "id": "0331",
        "name": "dumbbell incline twisted flyes",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1733.gif",
        "id": "1733",
        "name": "dumbbell incline two arm extension",
        "target": "triceps"
    },
    {
        "bodyPart": "back",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/3541.gif",
        "id": "3541",
        "name": "dumbbell incline y-raise",
        "target": "upper back"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0332.gif",
        "id": "0332",
        "name": "dumbbell iron cross",
        "target": "delts"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0333.gif",
        "id": "0333",
        "name": "dumbbell kickback",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1734.gif",
        "id": "1734",
        "name": "dumbbell kickbacks on exercise ball",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1660.gif",
        "id": "1660",
        "name": "dumbbell kneeling bicep curl exercise ball",
        "target": "biceps"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0334.gif",
        "id": "0334",
        "name": "dumbbell lateral raise",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0335.gif",
        "id": "0335",
        "name": "dumbbell lateral to front raise",
        "target": "delts"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0336.gif",
        "id": "0336",
        "name": "dumbbell lunge",
        "target": "glutes"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1658.gif",
        "id": "1658",
        "name": "dumbbell lunge with bicep curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0337.gif",
        "id": "0337",
        "name": "dumbbell lying  extension (across face)",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1729.gif",
        "id": "1729",
        "name": "dumbbell lying alternate extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0338.gif",
        "id": "0338",
        "name": "dumbbell lying elbow press",
        "target": "triceps"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0863.gif",
        "id": "0863",
        "name": "dumbbell lying external shoulder rotation",
        "target": "delts"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0339.gif",
        "id": "0339",
        "name": "dumbbell lying femoral",
        "target": "hamstrings"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0340.gif",
        "id": "0340",
        "name": "dumbbell lying hammer press",
        "target": "pectorals"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/2470.gif",
        "id": "2470",
        "name": "dumbbell lying on floor rear delt raise",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0341.gif",
        "id": "0341",
        "name": "dumbbell lying one arm deltoid rear",
        "target": "delts"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0343.gif",
        "id": "0343",
        "name": "dumbbell lying one arm press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0342.gif",
        "id": "0342",
        "name": "dumbbell lying one arm press v. 2",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0344.gif",
        "id": "0344",
        "name": "dumbbell lying one arm pronated triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0345.gif",
        "id": "0345",
        "name": "dumbbell lying one arm rear lateral raise",
        "target": "delts"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0346.gif",
        "id": "0346",
        "name": "dumbbell lying one arm supinated triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "lower arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0347.gif",
        "id": "0347",
        "name": "dumbbell lying pronation",
        "target": "forearms"
    },
    {
        "bodyPart": "lower arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/2705.gif",
        "id": "2705",
        "name": "dumbbell lying pronation on floor",
        "target": "forearms"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1284.gif",
        "id": "1284",
        "name": "dumbbell lying pullover on exercise ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "back",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1328.gif",
        "id": "1328",
        "name": "dumbbell lying rear delt row",
        "target": "upper back"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0348.gif",
        "id": "0348",
        "name": "dumbbell lying rear lateral raise",
        "target": "delts"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1735.gif",
        "id": "1735",
        "name": "dumbbell lying single extension",
        "target": "triceps"
    },
    {
        "bodyPart": "lower arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0349.gif",
        "id": "0349",
        "name": "dumbbell lying supination",
        "target": "forearms"
    },
    {
        "bodyPart": "lower arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/2706.gif",
        "id": "2706",
        "name": "dumbbell lying supination on floor",
        "target": "forearms"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1661.gif",
        "id": "1661",
        "name": "dumbbell lying supine biceps curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0350.gif",
        "id": "0350",
        "name": "dumbbell lying supine curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0351.gif",
        "id": "0351",
        "name": "dumbbell lying triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1662.gif",
        "id": "1662",
        "name": "dumbbell lying wide curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0352.gif",
        "id": "0352",
        "name": "dumbbell neutral grip bench press",
        "target": "triceps"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1285.gif",
        "id": "1285",
        "name": "dumbbell one arm bench fly",
        "target": "pectorals"
    },
    {
        "bodyPart": "back",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0292.gif",
        "id": "0292",
        "name": "dumbbell one arm bent-over row",
        "target": "upper back"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1286.gif",
        "id": "1286",
        "name": "dumbbell one arm chest fly on exercise ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0353.gif",
        "id": "0353",
        "name": "dumbbell one arm concetration curl (on stability ball)",
        "target": "biceps"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1287.gif",
        "id": "1287",
        "name": "dumbbell one arm decline chest press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1288.gif",
        "id": "1288",
        "name": "dumbbell one arm fly on exercise ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1736.gif",
        "id": "1736",
        "name": "dumbbell one arm french press on exercise ball",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1663.gif",
        "id": "1663",
        "name": "dumbbell one arm hammer preacher curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1621.gif",
        "id": "1621",
        "name": "dumbbell one arm hammer press on exercise ball",
        "target": "triceps"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1289.gif",
        "id": "1289",
        "name": "dumbbell one arm incline chest press",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0354.gif",
        "id": "0354",
        "name": "dumbbell one arm kickback",
        "target": "triceps"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0355.gif",
        "id": "0355",
        "name": "dumbbell one arm lateral raise",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0356.gif",
        "id": "0356",
        "name": "dumbbell one arm lateral raise with support",
        "target": "delts"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1290.gif",
        "id": "1290",
        "name": "dumbbell one arm press on exercise ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1665.gif",
        "id": "1665",
        "name": "dumbbell one arm prone curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1666.gif",
        "id": "1666",
        "name": "dumbbell one arm prone hammer curl",
        "target": "biceps"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1291.gif",
        "id": "1291",
        "name": "dumbbell one arm pullover on exercise ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "lower arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0358.gif",
        "id": "0358",
        "name": "dumbbell one arm revers wrist curl",
        "target": "forearms"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0359.gif",
        "id": "0359",
        "name": "dumbbell one arm reverse fly (with support)",
        "target": "delts"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1622.gif",
        "id": "1622",
        "name": "dumbbell one arm reverse grip press",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1414.gif",
        "id": "1414",
        "name": "dumbbell one arm reverse preacher curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1667.gif",
        "id": "1667",
        "name": "dumbbell one arm reverse spider curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1668.gif",
        "id": "1668",
        "name": "dumbbell one arm seated bicep curl on exercise ball",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1669.gif",
        "id": "1669",
        "name": "dumbbell one arm seated hammer curl",
        "target": "biceps"
    },
    {
        "bodyPart": "lower arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1415.gif",
        "id": "1415",
        "name": "dumbbell one arm seated neutral wrist curl",
        "target": "forearms"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0361.gif",
        "id": "0361",
        "name": "dumbbell one arm shoulder press",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0360.gif",
        "id": "0360",
        "name": "dumbbell one arm shoulder press v. 2",
        "target": "delts"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/3888.gif",
        "id": "3888",
        "name": "dumbbell one arm snatch",
        "target": "glutes"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1670.gif",
        "id": "1670",
        "name": "dumbbell one arm standing curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1671.gif",
        "id": "1671",
        "name": "dumbbell one arm standing hammer curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0362.gif",
        "id": "0362",
        "name": "dumbbell one arm triceps extension (on bench)",
        "target": "triceps"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0363.gif",
        "id": "0363",
        "name": "dumbbell one arm upright row",
        "target": "delts"
    },
    {
        "bodyPart": "lower arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0364.gif",
        "id": "0364",
        "name": "dumbbell one arm wrist curl",
        "target": "forearms"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1672.gif",
        "id": "1672",
        "name": "dumbbell one arm zottman preacher curl",
        "target": "biceps"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1292.gif",
        "id": "1292",
        "name": "dumbbell one leg fly on exercise ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0365.gif",
        "id": "0365",
        "name": "dumbbell over bench neutral wrist curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0366.gif",
        "id": "0366",
        "name": "dumbbell over bench one arm  neutral wrist curl",
        "target": "biceps"
    },
    {
        "bodyPart": "lower arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1441.gif",
        "id": "1441",
        "name": "dumbbell over bench one arm reverse wrist curl",
        "target": "forearms"
    },
    {
        "bodyPart": "lower arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0367.gif",
        "id": "0367",
        "name": "dumbbell over bench one arm wrist curl",
        "target": "forearms"
    },
    {
        "bodyPart": "lower arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0368.gif",
        "id": "0368",
        "name": "dumbbell over bench revers wrist curl",
        "target": "forearms"
    },
    {
        "bodyPart": "lower arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0369.gif",
        "id": "0369",
        "name": "dumbbell over bench wrist curl",
        "target": "forearms"
    },
    {
        "bodyPart": "back",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1329.gif",
        "id": "1329",
        "name": "dumbbell palm rotational bent over row",
        "target": "upper back"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1623.gif",
        "id": "1623",
        "name": "dumbbell palms in incline bench press",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0370.gif",
        "id": "0370",
        "name": "dumbbell peacher hammer curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0371.gif",
        "id": "0371",
        "name": "dumbbell plyo squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0372.gif",
        "id": "0372",
        "name": "dumbbell preacher curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1673.gif",
        "id": "1673",
        "name": "dumbbell preacher curl over exercise ball",
        "target": "biceps"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1293.gif",
        "id": "1293",
        "name": "dumbbell press on exercise ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0373.gif",
        "id": "0373",
        "name": "dumbbell pronate-grip triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0374.gif",
        "id": "0374",
        "name": "dumbbell prone incline curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1674.gif",
        "id": "1674",
        "name": "dumbbell prone incline hammer curl",
        "target": "biceps"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0375.gif",
        "id": "0375",
        "name": "dumbbell pullover",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1294.gif",
        "id": "1294",
        "name": "dumbbell pullover hip extension on exercise ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1295.gif",
        "id": "1295",
        "name": "dumbbell pullover on exercise ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1700.gif",
        "id": "1700",
        "name": "dumbbell push press",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0376.gif",
        "id": "0376",
        "name": "dumbbell raise",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/2292.gif",
        "id": "2292",
        "name": "dumbbell rear delt raise",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0377.gif",
        "id": "0377",
        "name": "dumbbell rear delt row_shoulder",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0378.gif",
        "id": "0378",
        "name": "dumbbell rear fly",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0380.gif",
        "id": "0380",
        "name": "dumbbell rear lateral raise",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0379.gif",
        "id": "0379",
        "name": "dumbbell rear lateral raise (support head)",
        "target": "delts"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0381.gif",
        "id": "0381",
        "name": "dumbbell rear lunge",
        "target": "glutes"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0382.gif",
        "id": "0382",
        "name": "dumbbell revers grip biceps curl",
        "target": "biceps"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1624.gif",
        "id": "1624",
        "name": "dumbbell reverse bench press",
        "target": "pectorals"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0383.gif",
        "id": "0383",
        "name": "dumbbell reverse fly",
        "target": "delts"
    },
    {
        "bodyPart": "back",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1330.gif",
        "id": "1330",
        "name": "dumbbell reverse grip incline bench one arm row",
        "target": "upper back"
    },
    {
        "bodyPart": "back",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1331.gif",
        "id": "1331",
        "name": "dumbbell reverse grip incline bench two arm row",
        "target": "upper back"
    },
    {
        "bodyPart": "back",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/2327.gif",
        "id": "2327",
        "name": "dumbbell reverse grip row (female)",
        "target": "upper back"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0384.gif",
        "id": "0384",
        "name": "dumbbell reverse preacher curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1675.gif",
        "id": "1675",
        "name": "dumbbell reverse spider curl",
        "target": "biceps"
    },
    {
        "bodyPart": "lower arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0385.gif",
        "id": "0385",
        "name": "dumbbell reverse wrist curl",
        "target": "forearms"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1459.gif",
        "id": "1459",
        "name": "dumbbell romanian deadlift",
        "target": "glutes"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0386.gif",
        "id": "0386",
        "name": "dumbbell rotation reverse fly",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/2397.gif",
        "id": "2397",
        "name": "dumbbell scott press",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0387.gif",
        "id": "0387",
        "name": "dumbbell seated alternate front raise",
        "target": "delts"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1676.gif",
        "id": "1676",
        "name": "dumbbell seated alternate hammer curl on exercise ball",
        "target": "biceps"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0388.gif",
        "id": "0388",
        "name": "dumbbell seated alternate press",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/3546.gif",
        "id": "3546",
        "name": "dumbbell seated alternate shoulder",
        "target": "delts"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0389.gif",
        "id": "0389",
        "name": "dumbbell seated bench extension",
        "target": "triceps"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/2317.gif",
        "id": "2317",
        "name": "dumbbell seated bent arm lateral raise",
        "target": "delts"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1730.gif",
        "id": "1730",
        "name": "dumbbell seated bent over alternate kickback",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1737.gif",
        "id": "1737",
        "name": "dumbbell seated bent over triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1677.gif",
        "id": "1677",
        "name": "dumbbell seated bicep curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0390.gif",
        "id": "0390",
        "name": "dumbbell seated biceps curl (on stability ball)",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/3547.gif",
        "id": "3547",
        "name": "dumbbell seated biceps curl to shoulder press",
        "target": "biceps"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1379.gif",
        "id": "1379",
        "name": "dumbbell seated calf raise",
        "target": "calves"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0391.gif",
        "id": "0391",
        "name": "dumbbell seated curl",
        "target": "biceps"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0392.gif",
        "id": "0392",
        "name": "dumbbell seated front raise",
        "target": "delts"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1678.gif",
        "id": "1678",
        "name": "dumbbell seated hammer curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0393.gif",
        "id": "0393",
        "name": "dumbbell seated inner biceps curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0394.gif",
        "id": "0394",
        "name": "dumbbell seated kickback",
        "target": "triceps"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0396.gif",
        "id": "0396",
        "name": "dumbbell seated lateral raise",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0395.gif",
        "id": "0395",
        "name": "dumbbell seated lateral raise v. 2",
        "target": "delts"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0397.gif",
        "id": "0397",
        "name": "dumbbell seated neutral wrist curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1679.gif",
        "id": "1679",
        "name": "dumbbell seated one arm bicep curl on exercise ball with leg raised",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0398.gif",
        "id": "0398",
        "name": "dumbbell seated one arm kickback",
        "target": "triceps"
    },
    {
        "bodyPart": "lower arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0399.gif",
        "id": "0399",
        "name": "dumbbell seated one arm rotate",
        "target": "forearms"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0400.gif",
        "id": "0400",
        "name": "dumbbell seated one leg calf raise",
        "target": "calves"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1380.gif",
        "id": "1380",
        "name": "dumbbell seated one leg calf raise - hammer grip",
        "target": "calves"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1381.gif",
        "id": "1381",
        "name": "dumbbell seated one leg calf raise - palm up",
        "target": "calves"
    },
    {
        "bodyPart": "lower arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0401.gif",
        "id": "0401",
        "name": "dumbbell seated palms up wrist curl",
        "target": "forearms"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0402.gif",
        "id": "0402",
        "name": "dumbbell seated preacher curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0403.gif",
        "id": "0403",
        "name": "dumbbell seated revers grip concentration curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1738.gif",
        "id": "1738",
        "name": "dumbbell seated reverse grip one arm overhead tricep extension",
        "target": "triceps"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0405.gif",
        "id": "0405",
        "name": "dumbbell seated shoulder press",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0404.gif",
        "id": "0404",
        "name": "dumbbell seated shoulder press (parallel grip)",
        "target": "delts"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/2188.gif",
        "id": "2188",
        "name": "dumbbell seated triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "back",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0406.gif",
        "id": "0406",
        "name": "dumbbell shrug",
        "target": "traps"
    },
    {
        "bodyPart": "waist",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0407.gif",
        "id": "0407",
        "name": "dumbbell side bend",
        "target": "abs"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0408.gif",
        "id": "0408",
        "name": "dumbbell side lying one hand raise",
        "target": "delts"
    },
    {
        "bodyPart": "back",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/3664.gif",
        "id": "3664",
        "name": "dumbbell side plank with rear fly",
        "target": "upper back"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/3548.gif",
        "id": "3548",
        "name": "dumbbell single arm overhead carry",
        "target": "delts"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0409.gif",
        "id": "0409",
        "name": "dumbbell single leg calf raise",
        "target": "calves"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1757.gif",
        "id": "1757",
        "name": "dumbbell single leg deadlift",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/2805.gif",
        "id": "2805",
        "name": "dumbbell single leg deadlift with stepbox support",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0410.gif",
        "id": "0410",
        "name": "dumbbell single leg split squat",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0411.gif",
        "id": "0411",
        "name": "dumbbell single leg squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0413.gif",
        "id": "0413",
        "name": "dumbbell squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/3560.gif",
        "id": "3560",
        "name": "dumbbell standing alternate hammer curl and press",
        "target": "biceps"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0414.gif",
        "id": "0414",
        "name": "dumbbell standing alternate overhead press",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0415.gif",
        "id": "0415",
        "name": "dumbbell standing alternate raise",
        "target": "delts"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1739.gif",
        "id": "1739",
        "name": "dumbbell standing alternating tricep kickback",
        "target": "triceps"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/2143.gif",
        "id": "2143",
        "name": "dumbbell standing around world",
        "target": "delts"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1740.gif",
        "id": "1740",
        "name": "dumbbell standing bent over one arm triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1741.gif",
        "id": "1741",
        "name": "dumbbell standing bent over two arm triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0416.gif",
        "id": "0416",
        "name": "dumbbell standing biceps curl",
        "target": "biceps"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0417.gif",
        "id": "0417",
        "name": "dumbbell standing calf raise",
        "target": "calves"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0418.gif",
        "id": "0418",
        "name": "dumbbell standing concentration curl",
        "target": "biceps"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0419.gif",
        "id": "0419",
        "name": "dumbbell standing front raise above head",
        "target": "delts"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/2321.gif",
        "id": "2321",
        "name": "dumbbell standing inner biceps curl v. 2",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0420.gif",
        "id": "0420",
        "name": "dumbbell standing kickback",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0421.gif",
        "id": "0421",
        "name": "dumbbell standing one arm concentration curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0422.gif",
        "id": "0422",
        "name": "dumbbell standing one arm curl (over incline bench)",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1680.gif",
        "id": "1680",
        "name": "dumbbell standing one arm curl over incline bench",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0423.gif",
        "id": "0423",
        "name": "dumbbell standing one arm extension",
        "target": "triceps"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0424.gif",
        "id": "0424",
        "name": "dumbbell standing one arm palm in press",
        "target": "delts"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0425.gif",
        "id": "0425",
        "name": "dumbbell standing one arm reverse curl",
        "target": "biceps"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0426.gif",
        "id": "0426",
        "name": "dumbbell standing overhead press",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0427.gif",
        "id": "0427",
        "name": "dumbbell standing palms in press",
        "target": "delts"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0428.gif",
        "id": "0428",
        "name": "dumbbell standing preacher curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0429.gif",
        "id": "0429",
        "name": "dumbbell standing reverse curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0430.gif",
        "id": "0430",
        "name": "dumbbell standing triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/2293.gif",
        "id": "2293",
        "name": "dumbbell standing zottman preacher curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1684.gif",
        "id": "1684",
        "name": "dumbbell step up single leg balance with bicep curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0431.gif",
        "id": "0431",
        "name": "dumbbell step-up",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/2796.gif",
        "id": "2796",
        "name": "dumbbell step-up lunge",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/2812.gif",
        "id": "2812",
        "name": "dumbbell step-up split squat",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0432.gif",
        "id": "0432",
        "name": "dumbbell stiff leg deadlift",
        "target": "glutes"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0433.gif",
        "id": "0433",
        "name": "dumbbell straight arm pullover",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0434.gif",
        "id": "0434",
        "name": "dumbbell straight leg deadlift",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/2808.gif",
        "id": "2808",
        "name": "dumbbell sumo pull through",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/2803.gif",
        "id": "2803",
        "name": "dumbbell supported squat",
        "target": "quads"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0436.gif",
        "id": "0436",
        "name": "dumbbell tate press",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1742.gif",
        "id": "1742",
        "name": "dumbbell tricep kickback with stork stance",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1743.gif",
        "id": "1743",
        "name": "dumbbell twisting bench press",
        "target": "triceps"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0437.gif",
        "id": "0437",
        "name": "dumbbell upright row",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1765.gif",
        "id": "1765",
        "name": "dumbbell upright row (back pov)",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0864.gif",
        "id": "0864",
        "name": "dumbbell upright shoulder external rotation",
        "target": "delts"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/5201.gif",
        "id": "5201",
        "name": "dumbbell waiter biceps curl",
        "target": "biceps"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0438.gif",
        "id": "0438",
        "name": "dumbbell w-press",
        "target": "delts"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0439.gif",
        "id": "0439",
        "name": "dumbbell zottman curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/2294.gif",
        "id": "2294",
        "name": "dumbbell zottman preacher curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/2189.gif",
        "id": "2189",
        "name": "dumbbells seated triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1167.gif",
        "id": "1167",
        "name": "dynamic chest stretch (male)",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3287.gif",
        "id": "3287",
        "name": "elbow dips",
        "target": "triceps"
    },
    {
        "bodyPart": "back",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1772.gif",
        "id": "1772",
        "name": "elbow lift - reverse push-up",
        "target": "upper back"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0443.gif",
        "id": "0443",
        "name": "elbow-to-knee",
        "target": "abs"
    },
    {
        "bodyPart": "back",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3292.gif",
        "id": "3292",
        "name": "elevator",
        "target": "upper back"
    },
    {
        "bodyPart": "back",
        "equipment": "stability ball",
        "gifUrl": "/assets/gifs/1332.gif",
        "id": "1332",
        "name": "exercise ball alternating arm ups",
        "target": "lats"
    },
    {
        "bodyPart": "back",
        "equipment": "stability ball",
        "gifUrl": "/assets/gifs/1333.gif",
        "id": "1333",
        "name": "exercise ball back extension with arms extended",
        "target": "spine"
    },
    {
        "bodyPart": "back",
        "equipment": "stability ball",
        "gifUrl": "/assets/gifs/1334.gif",
        "id": "1334",
        "name": "exercise ball back extension with hands behind head",
        "target": "spine"
    },
    {
        "bodyPart": "back",
        "equipment": "stability ball",
        "gifUrl": "/assets/gifs/1335.gif",
        "id": "1335",
        "name": "exercise ball back extension with knees off ground",
        "target": "spine"
    },
    {
        "bodyPart": "back",
        "equipment": "stability ball",
        "gifUrl": "/assets/gifs/1336.gif",
        "id": "1336",
        "name": "exercise ball back extension with rotation",
        "target": "spine"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "stability ball",
        "gifUrl": "/assets/gifs/1744.gif",
        "id": "1744",
        "name": "exercise ball dip",
        "target": "triceps"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "stability ball",
        "gifUrl": "/assets/gifs/1559.gif",
        "id": "1559",
        "name": "exercise ball hip flexor stretch",
        "target": "glutes"
    },
    {
        "bodyPart": "back",
        "equipment": "stability ball",
        "gifUrl": "/assets/gifs/1338.gif",
        "id": "1338",
        "name": "exercise ball hug",
        "target": "spine"
    },
    {
        "bodyPart": "back",
        "equipment": "stability ball",
        "gifUrl": "/assets/gifs/1339.gif",
        "id": "1339",
        "name": "exercise ball lat stretch",
        "target": "lats"
    },
    {
        "bodyPart": "back",
        "equipment": "stability ball",
        "gifUrl": "/assets/gifs/1341.gif",
        "id": "1341",
        "name": "exercise ball lower back stretch (pyramid)",
        "target": "lats"
    },
    {
        "bodyPart": "back",
        "equipment": "stability ball",
        "gifUrl": "/assets/gifs/1342.gif",
        "id": "1342",
        "name": "exercise ball lying side lat stretch",
        "target": "lats"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1382.gif",
        "id": "1382",
        "name": "exercise ball on the wall calf raise",
        "target": "calves"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/3241.gif",
        "id": "3241",
        "name": "exercise ball on the wall calf raise (tennis ball between ankles)",
        "target": "calves"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/3240.gif",
        "id": "3240",
        "name": "exercise ball on the wall calf raise (tennis ball between knees)",
        "target": "calves"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "stability ball",
        "gifUrl": "/assets/gifs/1416.gif",
        "id": "1416",
        "name": "exercise ball one leg prone lower body rotation",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "stability ball",
        "gifUrl": "/assets/gifs/1417.gif",
        "id": "1417",
        "name": "exercise ball one legged diagonal kick hamstring curl",
        "target": "glutes"
    },
    {
        "bodyPart": "chest",
        "equipment": "stability ball",
        "gifUrl": "/assets/gifs/1296.gif",
        "id": "1296",
        "name": "exercise ball pike push up",
        "target": "pectorals"
    },
    {
        "bodyPart": "back",
        "equipment": "stability ball",
        "gifUrl": "/assets/gifs/1343.gif",
        "id": "1343",
        "name": "exercise ball prone leg raise",
        "target": "spine"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "stability ball",
        "gifUrl": "/assets/gifs/1560.gif",
        "id": "1560",
        "name": "exercise ball seated hamstring stretch",
        "target": "hamstrings"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "stability ball",
        "gifUrl": "/assets/gifs/1745.gif",
        "id": "1745",
        "name": "exercise ball seated triceps stretch",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/1746.gif",
        "id": "1746",
        "name": "exercise ball supine triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "ez barbell",
        "gifUrl": "/assets/gifs/1747.gif",
        "id": "1747",
        "name": "ez bar french press on exercise ball",
        "target": "triceps"
    },
    {
        "bodyPart": "back",
        "equipment": "ez barbell",
        "gifUrl": "/assets/gifs/3010.gif",
        "id": "3010",
        "name": "ez bar lying bent arms pullover",
        "target": "lats"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "ez barbell",
        "gifUrl": "/assets/gifs/1748.gif",
        "id": "1748",
        "name": "ez bar lying close grip triceps extension behind head",
        "target": "triceps"
    },
    {
        "bodyPart": "back",
        "equipment": "ez barbell",
        "gifUrl": "/assets/gifs/1344.gif",
        "id": "1344",
        "name": "ez bar reverse grip bent over row",
        "target": "upper back"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "ez barbell",
        "gifUrl": "/assets/gifs/1682.gif",
        "id": "1682",
        "name": "ez bar seated close grip concentration curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "ez barbell",
        "gifUrl": "/assets/gifs/1749.gif",
        "id": "1749",
        "name": "ez bar standing french press",
        "target": "triceps"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "ez barbell",
        "gifUrl": "/assets/gifs/0445.gif",
        "id": "0445",
        "name": "ez barbell anti gravity press",
        "target": "delts"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "ez barbell",
        "gifUrl": "/assets/gifs/1627.gif",
        "id": "1627",
        "name": "ez barbell close grip preacher curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "ez barbell",
        "gifUrl": "/assets/gifs/0446.gif",
        "id": "0446",
        "name": "ez barbell close-grip curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "ez barbell",
        "gifUrl": "/assets/gifs/0447.gif",
        "id": "0447",
        "name": "ez barbell curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "ez barbell",
        "gifUrl": "/assets/gifs/0448.gif",
        "id": "0448",
        "name": "ez barbell decline close grip face press",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "ez barbell",
        "gifUrl": "/assets/gifs/2186.gif",
        "id": "2186",
        "name": "ez barbell decline triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "ez barbell",
        "gifUrl": "/assets/gifs/0449.gif",
        "id": "0449",
        "name": "ez barbell incline triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "ez barbell",
        "gifUrl": "/assets/gifs/0450.gif",
        "id": "0450",
        "name": "ez barbell jm bench press",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "ez barbell",
        "gifUrl": "/assets/gifs/0451.gif",
        "id": "0451",
        "name": "ez barbell reverse grip curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "ez barbell",
        "gifUrl": "/assets/gifs/0452.gif",
        "id": "0452",
        "name": "ez barbell reverse grip preacher curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "ez barbell",
        "gifUrl": "/assets/gifs/1458.gif",
        "id": "1458",
        "name": "ez barbell seated curls",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "ez barbell",
        "gifUrl": "/assets/gifs/0453.gif",
        "id": "0453",
        "name": "ez barbell seated triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "ez barbell",
        "gifUrl": "/assets/gifs/0454.gif",
        "id": "0454",
        "name": "ez barbell spider curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "ez barbell",
        "gifUrl": "/assets/gifs/1628.gif",
        "id": "1628",
        "name": "ez barbell spider curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "ez barbell",
        "gifUrl": "/assets/gifs/2404.gif",
        "id": "2404",
        "name": "ez-bar biceps curl (with arm blaster)",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "ez barbell",
        "gifUrl": "/assets/gifs/2432.gif",
        "id": "2432",
        "name": "ez-bar close-grip bench press",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "ez barbell",
        "gifUrl": "/assets/gifs/2741.gif",
        "id": "2741",
        "name": "ez-barbell standing wide grip biceps curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/2133.gif",
        "id": "2133",
        "name": "farmers walk",
        "target": "quads"
    },
    {
        "bodyPart": "lower arms",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0455.gif",
        "id": "0455",
        "name": "finger curls",
        "target": "forearms"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3303.gif",
        "id": "3303",
        "name": "flag",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0456.gif",
        "id": "0456",
        "name": "flexion leg sit up (bent knee)",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0457.gif",
        "id": "0457",
        "name": "flexion leg sit up (straight arm)",
        "target": "abs"
    },
    {
        "bodyPart": "chest",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0458.gif",
        "id": "0458",
        "name": "floor fly (with barbell)",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0459.gif",
        "id": "0459",
        "name": "flutter kicks",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1472.gif",
        "id": "1472",
        "name": "forward jump",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3470.gif",
        "id": "3470",
        "name": "forward lunge (male)",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/3194.gif",
        "id": "3194",
        "name": "frankenstein squat",
        "target": "glutes"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/2429.gif",
        "id": "2429",
        "name": "frog crunch",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3301.gif",
        "id": "3301",
        "name": "frog planche",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3296.gif",
        "id": "3296",
        "name": "front lever",
        "target": "abs"
    },
    {
        "bodyPart": "back",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3295.gif",
        "id": "3295",
        "name": "front lever reps",
        "target": "upper back"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0464.gif",
        "id": "0464",
        "name": "front plank with twist",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3315.gif",
        "id": "3315",
        "name": "full maltese",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3299.gif",
        "id": "3299",
        "name": "full planche",
        "target": "abs"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3327.gif",
        "id": "3327",
        "name": "full planche push-up",
        "target": "pectorals"
    },
    {
        "bodyPart": "back",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0466.gif",
        "id": "0466",
        "name": "gironda sternum chin",
        "target": "lats"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3561.gif",
        "id": "3561",
        "name": "glute bridge march",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3523.gif",
        "id": "3523",
        "name": "glute bridge two legs on bench (male)",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3193.gif",
        "id": "3193",
        "name": "glute-ham raise",
        "target": "hamstrings"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0467.gif",
        "id": "0467",
        "name": "gorilla chin",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0469.gif",
        "id": "0469",
        "name": "groin crunch",
        "target": "abs"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "sled machine",
        "gifUrl": "/assets/gifs/1383.gif",
        "id": "1383",
        "name": "hack calf raise",
        "target": "calves"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "sled machine",
        "gifUrl": "/assets/gifs/1384.gif",
        "id": "1384",
        "name": "hack one leg calf raise",
        "target": "calves"
    },
    {
        "bodyPart": "cardio",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3221.gif",
        "id": "3221",
        "name": "half knee bends (male)",
        "target": "cardiovascular system"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3202.gif",
        "id": "3202",
        "name": "half sit-up (male)",
        "target": "abs"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1511.gif",
        "id": "1511",
        "name": "hamstring stretch",
        "target": "hamstrings"
    },
    {
        "bodyPart": "chest",
        "equipment": "upper body ergometer",
        "gifUrl": "/assets/gifs/2139.gif",
        "id": "2139",
        "name": "hands bike",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3218.gif",
        "id": "3218",
        "name": "hands clasped circular toe touch (male)",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3215.gif",
        "id": "3215",
        "name": "hands reversed clasped circular toe touch (male)",
        "target": "glutes"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3302.gif",
        "id": "3302",
        "name": "handstand",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0471.gif",
        "id": "0471",
        "name": "handstand push-up",
        "target": "triceps"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1764.gif",
        "id": "1764",
        "name": "hanging leg hip raise",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0472.gif",
        "id": "0472",
        "name": "hanging leg raise",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1761.gif",
        "id": "1761",
        "name": "hanging oblique knee raise",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0473.gif",
        "id": "0473",
        "name": "hanging pike",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0474.gif",
        "id": "0474",
        "name": "hanging straight leg hip raise",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0475.gif",
        "id": "0475",
        "name": "hanging straight leg raise",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0476.gif",
        "id": "0476",
        "name": "hanging straight twisting leg hip raise",
        "target": "abs"
    },
    {
        "bodyPart": "cardio",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3636.gif",
        "id": "3636",
        "name": "high knee against wall",
        "target": "cardiovascular system"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0484.gif",
        "id": "0484",
        "name": "hip raise (bent knee)",
        "target": "abs"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1418.gif",
        "id": "1418",
        "name": "hug keens to chest",
        "target": "glutes"
    },
    {
        "bodyPart": "chest",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/3234.gif",
        "id": "3234",
        "name": "hyght dumbbell fly",
        "target": "pectorals"
    },
    {
        "bodyPart": "back",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0489.gif",
        "id": "0489",
        "name": "hyperextension",
        "target": "spine"
    },
    {
        "bodyPart": "back",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0488.gif",
        "id": "0488",
        "name": "hyperextension (on bench)",
        "target": "spine"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3289.gif",
        "id": "3289",
        "name": "impossible dips",
        "target": "triceps"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1471.gif",
        "id": "1471",
        "name": "inchworm",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3698.gif",
        "id": "3698",
        "name": "inchworm v. 2",
        "target": "abs"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0490.gif",
        "id": "0490",
        "name": "incline close-grip push-up",
        "target": "triceps"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0491.gif",
        "id": "0491",
        "name": "incline leg hip raise (leg straight)",
        "target": "abs"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0492.gif",
        "id": "0492",
        "name": "incline push up depth jump",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0493.gif",
        "id": "0493",
        "name": "incline push-up",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3785.gif",
        "id": "3785",
        "name": "incline push-up (on box)",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0494.gif",
        "id": "0494",
        "name": "incline reverse grip push-up",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3011.gif",
        "id": "3011",
        "name": "incline scapula push up",
        "target": "serratus anterior"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0495.gif",
        "id": "0495",
        "name": "incline twisting sit-up",
        "target": "abs"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "rope",
        "gifUrl": "/assets/gifs/1564.gif",
        "id": "1564",
        "name": "intermediate hip flexor and quad stretch",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0496.gif",
        "id": "0496",
        "name": "inverse leg curl (bench support)",
        "target": "hamstrings"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/2400.gif",
        "id": "2400",
        "name": "inverse leg curl (on pull-up cable machine)",
        "target": "hamstrings"
    },
    {
        "bodyPart": "back",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0499.gif",
        "id": "0499",
        "name": "inverted row",
        "target": "upper back"
    },
    {
        "bodyPart": "back",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/2300.gif",
        "id": "2300",
        "name": "inverted row bent knees",
        "target": "upper back"
    },
    {
        "bodyPart": "back",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/2298.gif",
        "id": "2298",
        "name": "inverted row on bench",
        "target": "upper back"
    },
    {
        "bodyPart": "back",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0497.gif",
        "id": "0497",
        "name": "inverted row v. 2",
        "target": "upper back"
    },
    {
        "bodyPart": "back",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0498.gif",
        "id": "0498",
        "name": "inverted row with straps",
        "target": "upper back"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1419.gif",
        "id": "1419",
        "name": "iron cross stretch",
        "target": "glutes"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1297.gif",
        "id": "1297",
        "name": "isometric chest squeeze",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0500.gif",
        "id": "0500",
        "name": "isometric wipers",
        "target": "pectorals"
    },
    {
        "bodyPart": "cardio",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0501.gif",
        "id": "0501",
        "name": "jack burpee",
        "target": "cardiovascular system"
    },
    {
        "bodyPart": "cardio",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3224.gif",
        "id": "3224",
        "name": "jack jump (male)",
        "target": "cardiovascular system"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0507.gif",
        "id": "0507",
        "name": "jackknife sit-up",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0508.gif",
        "id": "0508",
        "name": "janda sit-up",
        "target": "abs"
    },
    {
        "bodyPart": "cardio",
        "equipment": "rope",
        "gifUrl": "/assets/gifs/2612.gif",
        "id": "2612",
        "name": "jump rope",
        "target": "cardiovascular system"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0514.gif",
        "id": "0514",
        "name": "jump squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0513.gif",
        "id": "0513",
        "name": "jump squat v. 2",
        "target": "glutes"
    },
    {
        "bodyPart": "waist",
        "equipment": "kettlebell",
        "gifUrl": "/assets/gifs/0517.gif",
        "id": "0517",
        "name": "kettlebell advanced windmill",
        "target": "abs"
    },
    {
        "bodyPart": "lower arms",
        "equipment": "kettlebell",
        "gifUrl": "/assets/gifs/0518.gif",
        "id": "0518",
        "name": "kettlebell alternating hang clean",
        "target": "forearms"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "kettlebell",
        "gifUrl": "/assets/gifs/0520.gif",
        "id": "0520",
        "name": "kettlebell alternating press",
        "target": "delts"
    },
    {
        "bodyPart": "chest",
        "equipment": "kettlebell",
        "gifUrl": "/assets/gifs/0519.gif",
        "id": "0519",
        "name": "kettlebell alternating press on floor",
        "target": "pectorals"
    },
    {
        "bodyPart": "back",
        "equipment": "kettlebell",
        "gifUrl": "/assets/gifs/0521.gif",
        "id": "0521",
        "name": "kettlebell alternating renegade row",
        "target": "upper back"
    },
    {
        "bodyPart": "back",
        "equipment": "kettlebell",
        "gifUrl": "/assets/gifs/0522.gif",
        "id": "0522",
        "name": "kettlebell alternating row",
        "target": "upper back"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "kettlebell",
        "gifUrl": "/assets/gifs/0523.gif",
        "id": "0523",
        "name": "kettlebell arnold press",
        "target": "delts"
    },
    {
        "bodyPart": "waist",
        "equipment": "kettlebell",
        "gifUrl": "/assets/gifs/0524.gif",
        "id": "0524",
        "name": "kettlebell bent press",
        "target": "abs"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "kettlebell",
        "gifUrl": "/assets/gifs/0525.gif",
        "id": "0525",
        "name": "kettlebell bottoms up clean from the hang position",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "kettlebell",
        "gifUrl": "/assets/gifs/0526.gif",
        "id": "0526",
        "name": "kettlebell double alternating hang clean",
        "target": "biceps"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "kettlebell",
        "gifUrl": "/assets/gifs/0527.gif",
        "id": "0527",
        "name": "kettlebell double jerk",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "kettlebell",
        "gifUrl": "/assets/gifs/0528.gif",
        "id": "0528",
        "name": "kettlebell double push press",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "kettlebell",
        "gifUrl": "/assets/gifs/0529.gif",
        "id": "0529",
        "name": "kettlebell double snatch",
        "target": "delts"
    },
    {
        "bodyPart": "waist",
        "equipment": "kettlebell",
        "gifUrl": "/assets/gifs/0530.gif",
        "id": "0530",
        "name": "kettlebell double windmill",
        "target": "abs"
    },
    {
        "bodyPart": "chest",
        "equipment": "kettlebell",
        "gifUrl": "/assets/gifs/0531.gif",
        "id": "0531",
        "name": "kettlebell extended range one arm press on floor",
        "target": "pectorals"
    },
    {
        "bodyPart": "waist",
        "equipment": "kettlebell",
        "gifUrl": "/assets/gifs/0532.gif",
        "id": "0532",
        "name": "kettlebell figure 8",
        "target": "abs"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "kettlebell",
        "gifUrl": "/assets/gifs/0533.gif",
        "id": "0533",
        "name": "kettlebell front squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "kettlebell",
        "gifUrl": "/assets/gifs/0534.gif",
        "id": "0534",
        "name": "kettlebell goblet squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "kettlebell",
        "gifUrl": "/assets/gifs/0535.gif",
        "id": "0535",
        "name": "kettlebell hang clean",
        "target": "hamstrings"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "kettlebell",
        "gifUrl": "/assets/gifs/0536.gif",
        "id": "0536",
        "name": "kettlebell lunge pass through",
        "target": "glutes"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "kettlebell",
        "gifUrl": "/assets/gifs/0537.gif",
        "id": "0537",
        "name": "kettlebell one arm clean and jerk",
        "target": "delts"
    },
    {
        "bodyPart": "chest",
        "equipment": "kettlebell",
        "gifUrl": "/assets/gifs/1298.gif",
        "id": "1298",
        "name": "kettlebell one arm floor press",
        "target": "pectorals"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "kettlebell",
        "gifUrl": "/assets/gifs/0538.gif",
        "id": "0538",
        "name": "kettlebell one arm jerk",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "kettlebell",
        "gifUrl": "/assets/gifs/0539.gif",
        "id": "0539",
        "name": "kettlebell one arm military press to the side",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "kettlebell",
        "gifUrl": "/assets/gifs/0540.gif",
        "id": "0540",
        "name": "kettlebell one arm push press",
        "target": "delts"
    },
    {
        "bodyPart": "back",
        "equipment": "kettlebell",
        "gifUrl": "/assets/gifs/0541.gif",
        "id": "0541",
        "name": "kettlebell one arm row",
        "target": "upper back"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "kettlebell",
        "gifUrl": "/assets/gifs/0542.gif",
        "id": "0542",
        "name": "kettlebell one arm snatch",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "kettlebell",
        "gifUrl": "/assets/gifs/0543.gif",
        "id": "0543",
        "name": "kettlebell pirate supper legs",
        "target": "delts"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "kettlebell",
        "gifUrl": "/assets/gifs/0544.gif",
        "id": "0544",
        "name": "kettlebell pistol squat",
        "target": "glutes"
    },
    {
        "bodyPart": "chest",
        "equipment": "kettlebell",
        "gifUrl": "/assets/gifs/0545.gif",
        "id": "0545",
        "name": "kettlebell plyo push-up",
        "target": "pectorals"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "kettlebell",
        "gifUrl": "/assets/gifs/0546.gif",
        "id": "0546",
        "name": "kettlebell seated press",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "kettlebell",
        "gifUrl": "/assets/gifs/1438.gif",
        "id": "1438",
        "name": "kettlebell seated two arm military press",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "kettlebell",
        "gifUrl": "/assets/gifs/0547.gif",
        "id": "0547",
        "name": "kettlebell seesaw press",
        "target": "delts"
    },
    {
        "bodyPart": "back",
        "equipment": "kettlebell",
        "gifUrl": "/assets/gifs/0548.gif",
        "id": "0548",
        "name": "kettlebell sumo high pull",
        "target": "traps"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "kettlebell",
        "gifUrl": "/assets/gifs/0549.gif",
        "id": "0549",
        "name": "kettlebell swing",
        "target": "glutes"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "kettlebell",
        "gifUrl": "/assets/gifs/0550.gif",
        "id": "0550",
        "name": "kettlebell thruster",
        "target": "delts"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "kettlebell",
        "gifUrl": "/assets/gifs/0551.gif",
        "id": "0551",
        "name": "kettlebell turkish get up (squat style)",
        "target": "glutes"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "kettlebell",
        "gifUrl": "/assets/gifs/0552.gif",
        "id": "0552",
        "name": "kettlebell two arm clean",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "kettlebell",
        "gifUrl": "/assets/gifs/0553.gif",
        "id": "0553",
        "name": "kettlebell two arm military press",
        "target": "delts"
    },
    {
        "bodyPart": "back",
        "equipment": "kettlebell",
        "gifUrl": "/assets/gifs/1345.gif",
        "id": "1345",
        "name": "kettlebell two arm row",
        "target": "upper back"
    },
    {
        "bodyPart": "waist",
        "equipment": "kettlebell",
        "gifUrl": "/assets/gifs/0554.gif",
        "id": "0554",
        "name": "kettlebell windmill",
        "target": "abs"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0555.gif",
        "id": "0555",
        "name": "kick out sit",
        "target": "hamstrings"
    },
    {
        "bodyPart": "back",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0558.gif",
        "id": "0558",
        "name": "kipping muscle up",
        "target": "lats"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3640.gif",
        "id": "3640",
        "name": "knee touch crunch",
        "target": "abs"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/1420.gif",
        "id": "1420",
        "name": "kneeling jump squat",
        "target": "glutes"
    },
    {
        "bodyPart": "back",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1346.gif",
        "id": "1346",
        "name": "kneeling lat stretch",
        "target": "lats"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3239.gif",
        "id": "3239",
        "name": "kneeling plank tap shoulder (male)",
        "target": "abs"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3211.gif",
        "id": "3211",
        "name": "kneeling push-up (male)",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3288.gif",
        "id": "3288",
        "name": "korean dips",
        "target": "pectorals"
    },
    {
        "bodyPart": "back",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3418.gif",
        "id": "3418",
        "name": "l-pull-up",
        "target": "lats"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3419.gif",
        "id": "3419",
        "name": "l-sit on floor",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0562.gif",
        "id": "0562",
        "name": "landmine 180",
        "target": "abs"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/3237.gif",
        "id": "3237",
        "name": "landmine lateral raise",
        "target": "delts"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3300.gif",
        "id": "3300",
        "name": "lean planche",
        "target": "abs"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/2271.gif",
        "id": "2271",
        "name": "left hook. boxing",
        "target": "delts"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0570.gif",
        "id": "0570",
        "name": "leg pull in flat bench",
        "target": "abs"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1576.gif",
        "id": "1576",
        "name": "leg up hamstring stretch",
        "target": "hamstrings"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/2287.gif",
        "id": "2287",
        "name": "lever alternate leg press ",
        "target": "quads"
    },
    {
        "bodyPart": "back",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/0571.gif",
        "id": "0571",
        "name": "lever alternating narrow grip seated row ",
        "target": "upper back"
    },
    {
        "bodyPart": "back",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/0572.gif",
        "id": "0572",
        "name": "lever assisted chin-up",
        "target": "lats"
    },
    {
        "bodyPart": "back",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/0573.gif",
        "id": "0573",
        "name": "lever back extension",
        "target": "spine"
    },
    {
        "bodyPart": "back",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0574.gif",
        "id": "0574",
        "name": "lever bent over row ",
        "target": "upper back"
    },
    {
        "bodyPart": "back",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/3200.gif",
        "id": "3200",
        "name": "lever bent-over row with v-bar ",
        "target": "upper back"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/0575.gif",
        "id": "0575",
        "name": "lever bicep curl",
        "target": "biceps"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/2289.gif",
        "id": "2289",
        "name": "lever calf press ",
        "target": "calves"
    },
    {
        "bodyPart": "chest",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/0577.gif",
        "id": "0577",
        "name": "lever chest press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/0576.gif",
        "id": "0576",
        "name": "lever chest press ",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/0578.gif",
        "id": "0578",
        "name": "lever deadlift ",
        "target": "glutes"
    },
    {
        "bodyPart": "chest",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/1300.gif",
        "id": "1300",
        "name": "lever decline chest press",
        "target": "pectorals"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/1253.gif",
        "id": "1253",
        "name": "lever donkey calf raise",
        "target": "calves"
    },
    {
        "bodyPart": "back",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/0579.gif",
        "id": "0579",
        "name": "lever front pulldown",
        "target": "lats"
    },
    {
        "bodyPart": "back",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/0580.gif",
        "id": "0580",
        "name": "lever gripless shrug",
        "target": "traps"
    },
    {
        "bodyPart": "back",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/1439.gif",
        "id": "1439",
        "name": "lever gripless shrug v. 2",
        "target": "traps"
    },
    {
        "bodyPart": "lower arms",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/2288.gif",
        "id": "2288",
        "name": "lever gripper hands ",
        "target": "forearms"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/1615.gif",
        "id": "1615",
        "name": "lever hammer grip preacher curl",
        "target": "biceps"
    },
    {
        "bodyPart": "back",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/0581.gif",
        "id": "0581",
        "name": "lever high row ",
        "target": "upper back"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/2286.gif",
        "id": "2286",
        "name": "lever hip extension v. 2",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/2611.gif",
        "id": "2611",
        "name": "lever horizontal one leg press",
        "target": "glutes"
    },
    {
        "bodyPart": "chest",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/1299.gif",
        "id": "1299",
        "name": "lever incline chest press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/1479.gif",
        "id": "1479",
        "name": "lever incline chest press v. 2",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/0582.gif",
        "id": "0582",
        "name": "lever kneeling leg curl ",
        "target": "hamstrings"
    },
    {
        "bodyPart": "waist",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/0583.gif",
        "id": "0583",
        "name": "lever kneeling twist",
        "target": "abs"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/0584.gif",
        "id": "0584",
        "name": "lever lateral raise",
        "target": "delts"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/0585.gif",
        "id": "0585",
        "name": "lever leg extension",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/0586.gif",
        "id": "0586",
        "name": "lever lying leg curl",
        "target": "hamstrings"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/3195.gif",
        "id": "3195",
        "name": "lever lying two-one leg curl",
        "target": "hamstrings"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/0587.gif",
        "id": "0587",
        "name": "lever military press ",
        "target": "delts"
    },
    {
        "bodyPart": "back",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/0588.gif",
        "id": "0588",
        "name": "lever narrow grip seated row ",
        "target": "upper back"
    },
    {
        "bodyPart": "back",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0589.gif",
        "id": "0589",
        "name": "lever one arm bent over row ",
        "target": "upper back"
    },
    {
        "bodyPart": "back",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/1356.gif",
        "id": "1356",
        "name": "lever one arm lateral high row",
        "target": "upper back"
    },
    {
        "bodyPart": "back",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/1347.gif",
        "id": "1347",
        "name": "lever one arm lateral wide pulldown ",
        "target": "lats"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/0590.gif",
        "id": "0590",
        "name": "lever one arm shoulder press ",
        "target": "delts"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/0591.gif",
        "id": "0591",
        "name": "lever overhand triceps dip",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/0592.gif",
        "id": "0592",
        "name": "lever preacher curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/1614.gif",
        "id": "1614",
        "name": "lever preacher curl v. 2",
        "target": "biceps"
    },
    {
        "bodyPart": "back",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/2285.gif",
        "id": "2285",
        "name": "lever pullover ",
        "target": "lats"
    },
    {
        "bodyPart": "back",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/2736.gif",
        "id": "2736",
        "name": "lever reverse grip lateral pulldown ",
        "target": "lats"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/1616.gif",
        "id": "1616",
        "name": "lever reverse grip preacher curl",
        "target": "biceps"
    },
    {
        "bodyPart": "back",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/1348.gif",
        "id": "1348",
        "name": "lever reverse grip vertical row",
        "target": "upper back"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/0593.gif",
        "id": "0593",
        "name": "lever reverse hyperextension ",
        "target": "glutes"
    },
    {
        "bodyPart": "back",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/1349.gif",
        "id": "1349",
        "name": "lever reverse t-bar row",
        "target": "upper back"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/2315.gif",
        "id": "2315",
        "name": "lever rotary calf",
        "target": "calves"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/2335.gif",
        "id": "2335",
        "name": "lever seated calf press",
        "target": "calves"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/0594.gif",
        "id": "0594",
        "name": "lever seated calf raise ",
        "target": "calves"
    },
    {
        "bodyPart": "waist",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/1452.gif",
        "id": "1452",
        "name": "lever seated crunch",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/0595.gif",
        "id": "0595",
        "name": "lever seated crunch (chest pad)",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/3760.gif",
        "id": "3760",
        "name": "lever seated crunch v. 2",
        "target": "abs"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/1451.gif",
        "id": "1451",
        "name": "lever seated dip",
        "target": "triceps"
    },
    {
        "bodyPart": "chest",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/0596.gif",
        "id": "0596",
        "name": "lever seated fly",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/3759.gif",
        "id": "3759",
        "name": "lever seated good morning",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/0597.gif",
        "id": "0597",
        "name": "lever seated hip abduction",
        "target": "abductors"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/0598.gif",
        "id": "0598",
        "name": "lever seated hip adduction",
        "target": "adductors"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/0599.gif",
        "id": "0599",
        "name": "lever seated leg curl",
        "target": "hamstrings"
    },
    {
        "bodyPart": "waist",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/0600.gif",
        "id": "0600",
        "name": "lever seated leg raise crunch ",
        "target": "abs"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/0602.gif",
        "id": "0602",
        "name": "lever seated reverse fly",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/0601.gif",
        "id": "0601",
        "name": "lever seated reverse fly (parallel grip)",
        "target": "delts"
    },
    {
        "bodyPart": "back",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/1350.gif",
        "id": "1350",
        "name": "lever seated row",
        "target": "upper back"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/1385.gif",
        "id": "1385",
        "name": "lever seated squat calf raise on leg press machine",
        "target": "calves"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/0603.gif",
        "id": "0603",
        "name": "lever shoulder press ",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/0869.gif",
        "id": "0869",
        "name": "lever shoulder press  v. 2",
        "target": "delts"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/2318.gif",
        "id": "2318",
        "name": "lever shoulder press  v. 3",
        "target": "delts"
    },
    {
        "bodyPart": "back",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/0604.gif",
        "id": "0604",
        "name": "lever shrug ",
        "target": "traps"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/0605.gif",
        "id": "0605",
        "name": "lever standing calf raise",
        "target": "calves"
    },
    {
        "bodyPart": "chest",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/3758.gif",
        "id": "3758",
        "name": "lever standing chest press",
        "target": "pectorals"
    },
    {
        "bodyPart": "back",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/0606.gif",
        "id": "0606",
        "name": "lever t bar row ",
        "target": "upper back"
    },
    {
        "bodyPart": "back",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/1351.gif",
        "id": "1351",
        "name": "lever t-bar reverse grip row",
        "target": "upper back"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/0607.gif",
        "id": "0607",
        "name": "lever triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "back",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/1313.gif",
        "id": "1313",
        "name": "lever unilateral row",
        "target": "upper back"
    },
    {
        "bodyPart": "back",
        "equipment": "rope",
        "gifUrl": "/assets/gifs/0609.gif",
        "id": "0609",
        "name": "london bridge",
        "target": "upper back"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3013.gif",
        "id": "3013",
        "name": "low glute bridge on floor",
        "target": "glutes"
    },
    {
        "bodyPart": "back",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1352.gif",
        "id": "1352",
        "name": "lower back curl",
        "target": "spine"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3582.gif",
        "id": "3582",
        "name": "lunge with jump",
        "target": "glutes"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1688.gif",
        "id": "1688",
        "name": "lunge with twist",
        "target": "abs"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0613.gif",
        "id": "0613",
        "name": "lying (side) quads stretch",
        "target": "quads"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/2312.gif",
        "id": "2312",
        "name": "lying elbow to knee",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0620.gif",
        "id": "0620",
        "name": "lying leg raise flat bench",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0865.gif",
        "id": "0865",
        "name": "lying leg-hip raise",
        "target": "abs"
    },
    {
        "bodyPart": "chest",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/1301.gif",
        "id": "1301",
        "name": "machine inner chest press",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0624.gif",
        "id": "0624",
        "name": "march sit (wall)",
        "target": "glutes"
    },
    {
        "bodyPart": "back",
        "equipment": "medicine ball",
        "gifUrl": "/assets/gifs/1353.gif",
        "id": "1353",
        "name": "medicine ball catch and overhead throw",
        "target": "lats"
    },
    {
        "bodyPart": "chest",
        "equipment": "medicine ball",
        "gifUrl": "/assets/gifs/1302.gif",
        "id": "1302",
        "name": "medicine ball chest pass",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "medicine ball",
        "gifUrl": "/assets/gifs/1303.gif",
        "id": "1303",
        "name": "medicine ball chest push from 3 point stance",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "medicine ball",
        "gifUrl": "/assets/gifs/1304.gif",
        "id": "1304",
        "name": "medicine ball chest push multiple response",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "medicine ball",
        "gifUrl": "/assets/gifs/1305.gif",
        "id": "1305",
        "name": "medicine ball chest push single response",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "medicine ball",
        "gifUrl": "/assets/gifs/1312.gif",
        "id": "1312",
        "name": "medicine ball chest push with run release",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "medicine ball",
        "gifUrl": "/assets/gifs/1701.gif",
        "id": "1701",
        "name": "medicine ball close grip push up",
        "target": "triceps"
    },
    {
        "bodyPart": "back",
        "equipment": "medicine ball",
        "gifUrl": "/assets/gifs/1354.gif",
        "id": "1354",
        "name": "medicine ball overhead slam",
        "target": "upper back"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "medicine ball",
        "gifUrl": "/assets/gifs/1750.gif",
        "id": "1750",
        "name": "medicine ball supine chest throw",
        "target": "triceps"
    },
    {
        "bodyPart": "back",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0627.gif",
        "id": "0627",
        "name": "mixed grip chin-up",
        "target": "lats"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3217.gif",
        "id": "3217",
        "name": "modified hindu push-up (male)",
        "target": "pectorals"
    },
    {
        "bodyPart": "lower arms",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1421.gif",
        "id": "1421",
        "name": "modified push up to lower arms",
        "target": "forearms"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0628.gif",
        "id": "0628",
        "name": "monster walk",
        "target": "glutes"
    },
    {
        "bodyPart": "cardio",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0630.gif",
        "id": "0630",
        "name": "mountain climber",
        "target": "cardiovascular system"
    },
    {
        "bodyPart": "back",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0631.gif",
        "id": "0631",
        "name": "muscle up",
        "target": "lats"
    },
    {
        "bodyPart": "back",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1401.gif",
        "id": "1401",
        "name": "muscle-up (on vertical bar)",
        "target": "lats"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "stability ball",
        "gifUrl": "/assets/gifs/2328.gif",
        "id": "2328",
        "name": "narrow push-up on exercise ball",
        "target": "triceps"
    },
    {
        "bodyPart": "neck",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1403.gif",
        "id": "1403",
        "name": "neck side stretch",
        "target": "levator scapulae"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0634.gif",
        "id": "0634",
        "name": "negative crunch",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1495.gif",
        "id": "1495",
        "name": "oblique crunch v. 2",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0635.gif",
        "id": "0635",
        "name": "oblique crunches floor",
        "target": "abs"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "olympic barbell",
        "gifUrl": "/assets/gifs/0636.gif",
        "id": "0636",
        "name": "olympic barbell hammer curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "olympic barbell",
        "gifUrl": "/assets/gifs/0637.gif",
        "id": "0637",
        "name": "olympic barbell triceps extension",
        "target": "triceps"
    },
    {
        "bodyPart": "back",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1355.gif",
        "id": "1355",
        "name": "one arm against wall",
        "target": "lats"
    },
    {
        "bodyPart": "back",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0638.gif",
        "id": "0638",
        "name": "one arm chin-up",
        "target": "lats"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0639.gif",
        "id": "0639",
        "name": "one arm dip",
        "target": "triceps"
    },
    {
        "bodyPart": "waist",
        "equipment": "medicine ball",
        "gifUrl": "/assets/gifs/0640.gif",
        "id": "0640",
        "name": "one arm slam (with medicine ball)",
        "target": "abs"
    },
    {
        "bodyPart": "back",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1773.gif",
        "id": "1773",
        "name": "one arm towel row",
        "target": "upper back"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1386.gif",
        "id": "1386",
        "name": "one leg donkey calf raise",
        "target": "calves"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1387.gif",
        "id": "1387",
        "name": "one leg floor calf raise",
        "target": "calves"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1476.gif",
        "id": "1476",
        "name": "one leg squat",
        "target": "glutes"
    },
    {
        "bodyPart": "waist",
        "equipment": "weighted",
        "gifUrl": "/assets/gifs/0641.gif",
        "id": "0641",
        "name": "otis up",
        "target": "abs"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0642.gif",
        "id": "0642",
        "name": "outside leg kick push-up",
        "target": "glutes"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0643.gif",
        "id": "0643",
        "name": "overhead triceps stretch",
        "target": "triceps"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3147.gif",
        "id": "3147",
        "name": "pelvic tilt",
        "target": "abs"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1422.gif",
        "id": "1422",
        "name": "pelvic tilt into bridge",
        "target": "glutes"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "rope",
        "gifUrl": "/assets/gifs/1388.gif",
        "id": "1388",
        "name": "peroneals stretch",
        "target": "calves"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3662.gif",
        "id": "3662",
        "name": "pike-to-cobra push-up",
        "target": "glutes"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1306.gif",
        "id": "1306",
        "name": "plyo push up",
        "target": "pectorals"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1687.gif",
        "id": "1687",
        "name": "posterior step to overhead reach",
        "target": "abs"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "rope",
        "gifUrl": "/assets/gifs/1389.gif",
        "id": "1389",
        "name": "posterior tibialis stretch",
        "target": "calves"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3119.gif",
        "id": "3119",
        "name": "potty squat",
        "target": "abs"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3132.gif",
        "id": "3132",
        "name": "potty squat with support",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0648.gif",
        "id": "0648",
        "name": "power clean",
        "target": "hamstrings"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3665.gif",
        "id": "3665",
        "name": "power point plank",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3203.gif",
        "id": "3203",
        "name": "prisoner half sit-up (male)",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "stability ball",
        "gifUrl": "/assets/gifs/1707.gif",
        "id": "1707",
        "name": "prone twist on stability ball",
        "target": "abs"
    },
    {
        "bodyPart": "back",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0651.gif",
        "id": "0651",
        "name": "pull up (neutral grip)",
        "target": "lats"
    },
    {
        "bodyPart": "waist",
        "equipment": "stability ball",
        "gifUrl": "/assets/gifs/0650.gif",
        "id": "0650",
        "name": "pull-in (on stability ball)",
        "target": "abs"
    },
    {
        "bodyPart": "back",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0652.gif",
        "id": "0652",
        "name": "pull-up",
        "target": "lats"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1689.gif",
        "id": "1689",
        "name": "push and pull bodyweight",
        "target": "pectorals"
    },
    {
        "bodyPart": "cardio",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3638.gif",
        "id": "3638",
        "name": "push to run",
        "target": "cardiovascular system"
    },
    {
        "bodyPart": "chest",
        "equipment": "bosu ball",
        "gifUrl": "/assets/gifs/1307.gif",
        "id": "1307",
        "name": "push up on bosu ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0662.gif",
        "id": "0662",
        "name": "push-up",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "bosu ball",
        "gifUrl": "/assets/gifs/0653.gif",
        "id": "0653",
        "name": "push-up (bosu ball)",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "stability ball",
        "gifUrl": "/assets/gifs/0655.gif",
        "id": "0655",
        "name": "push-up (on stability ball)",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "stability ball",
        "gifUrl": "/assets/gifs/0656.gif",
        "id": "0656",
        "name": "push-up (on stability ball)",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0659.gif",
        "id": "0659",
        "name": "push-up (wall)",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0658.gif",
        "id": "0658",
        "name": "push-up (wall) v. 2",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0660.gif",
        "id": "0660",
        "name": "push-up close-grip off dumbbell",
        "target": "triceps"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0661.gif",
        "id": "0661",
        "name": "push-up inside leg kick",
        "target": "glutes"
    },
    {
        "bodyPart": "chest",
        "equipment": "medicine ball",
        "gifUrl": "/assets/gifs/0663.gif",
        "id": "0663",
        "name": "push-up medicine ball",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1467.gif",
        "id": "1467",
        "name": "push-up on lower arms",
        "target": "triceps"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3145.gif",
        "id": "3145",
        "name": "push-up plus",
        "target": "pectorals"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0664.gif",
        "id": "0664",
        "name": "push-up to side plank",
        "target": "abs"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3533.gif",
        "id": "3533",
        "name": "quads",
        "target": "quads"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3201.gif",
        "id": "3201",
        "name": "quarter sit-up",
        "target": "abs"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3552.gif",
        "id": "3552",
        "name": "quick feet v. 2",
        "target": "quads"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0666.gif",
        "id": "0666",
        "name": "raise single arm push-up",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0668.gif",
        "id": "0668",
        "name": "rear decline bridge",
        "target": "glutes"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0669.gif",
        "id": "0669",
        "name": "rear deltoid stretch",
        "target": "delts"
    },
    {
        "bodyPart": "back",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0670.gif",
        "id": "0670",
        "name": "rear pull-up",
        "target": "lats"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "rope",
        "gifUrl": "/assets/gifs/1582.gif",
        "id": "1582",
        "name": "reclining big toe pose with rope",
        "target": "hamstrings"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "resistance band",
        "gifUrl": "/assets/gifs/3236.gif",
        "id": "3236",
        "name": "resistance band hip thrusts on knees (female)",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "resistance band",
        "gifUrl": "/assets/gifs/3007.gif",
        "id": "3007",
        "name": "resistance band leg extension",
        "target": "quads"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "resistance band",
        "gifUrl": "/assets/gifs/3123.gif",
        "id": "3123",
        "name": "resistance band seated biceps curl",
        "target": "biceps"
    },
    {
        "bodyPart": "chest",
        "equipment": "resistance band",
        "gifUrl": "/assets/gifs/3124.gif",
        "id": "3124",
        "name": "resistance band seated chest press",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "resistance band",
        "gifUrl": "/assets/gifs/3006.gif",
        "id": "3006",
        "name": "resistance band seated hip abduction",
        "target": "abductors"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "resistance band",
        "gifUrl": "/assets/gifs/3122.gif",
        "id": "3122",
        "name": "resistance band seated shoulder press",
        "target": "delts"
    },
    {
        "bodyPart": "back",
        "equipment": "resistance band",
        "gifUrl": "/assets/gifs/3144.gif",
        "id": "3144",
        "name": "resistance band seated straight back row",
        "target": "upper back"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0872.gif",
        "id": "0872",
        "name": "reverse crunch",
        "target": "abs"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0672.gif",
        "id": "0672",
        "name": "reverse dip",
        "target": "triceps"
    },
    {
        "bodyPart": "back",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/0673.gif",
        "id": "0673",
        "name": "reverse grip machine lat pulldown",
        "target": "lats"
    },
    {
        "bodyPart": "back",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0674.gif",
        "id": "0674",
        "name": "reverse grip pull-up",
        "target": "lats"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "stability ball",
        "gifUrl": "/assets/gifs/0675.gif",
        "id": "0675",
        "name": "reverse hyper extension (on stability ball)",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1423.gif",
        "id": "1423",
        "name": "reverse hyper on flat bench",
        "target": "glutes"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3663.gif",
        "id": "3663",
        "name": "reverse plank with leg lift",
        "target": "abs"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0677.gif",
        "id": "0677",
        "name": "ring dips",
        "target": "triceps"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/2571.gif",
        "id": "2571",
        "name": "rocking frog stretch",
        "target": "glutes"
    },
    {
        "bodyPart": "back",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0678.gif",
        "id": "0678",
        "name": "rocky pull-up pulldown",
        "target": "lats"
    },
    {
        "bodyPart": "back",
        "equipment": "roller",
        "gifUrl": "/assets/gifs/2208.gif",
        "id": "2208",
        "name": "roller back stretch",
        "target": "spine"
    },
    {
        "bodyPart": "waist",
        "equipment": "roller",
        "gifUrl": "/assets/gifs/2204.gif",
        "id": "2204",
        "name": "roller body saw",
        "target": "abs"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "roller",
        "gifUrl": "/assets/gifs/2205.gif",
        "id": "2205",
        "name": "roller hip lat stretch",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "roller",
        "gifUrl": "/assets/gifs/2202.gif",
        "id": "2202",
        "name": "roller hip stretch",
        "target": "glutes"
    },
    {
        "bodyPart": "waist",
        "equipment": "roller",
        "gifUrl": "/assets/gifs/2206.gif",
        "id": "2206",
        "name": "roller reverse crunch",
        "target": "abs"
    },
    {
        "bodyPart": "chest",
        "equipment": "roller",
        "gifUrl": "/assets/gifs/2203.gif",
        "id": "2203",
        "name": "roller seated shoulder flexor depresor retractor",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "roller",
        "gifUrl": "/assets/gifs/2209.gif",
        "id": "2209",
        "name": "roller seated single leg shoulder flexor depresor retractor",
        "target": "pectorals"
    },
    {
        "bodyPart": "back",
        "equipment": "roller",
        "gifUrl": "/assets/gifs/2207.gif",
        "id": "2207",
        "name": "roller side lat stretch",
        "target": "lats"
    },
    {
        "bodyPart": "back",
        "equipment": "rope",
        "gifUrl": "/assets/gifs/0680.gif",
        "id": "0680",
        "name": "rope climb",
        "target": "upper back"
    },
    {
        "bodyPart": "cardio",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0685.gif",
        "id": "0685",
        "name": "run",
        "target": "cardiovascular system"
    },
    {
        "bodyPart": "cardio",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0684.gif",
        "id": "0684",
        "name": "run (equipment)",
        "target": "cardiovascular system"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1585.gif",
        "id": "1585",
        "name": "runners stretch",
        "target": "hamstrings"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0687.gif",
        "id": "0687",
        "name": "russian twist",
        "target": "abs"
    },
    {
        "bodyPart": "back",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3012.gif",
        "id": "3012",
        "name": "scapula dips",
        "target": "traps"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3021.gif",
        "id": "3021",
        "name": "scapula push-up",
        "target": "serratus anterior"
    },
    {
        "bodyPart": "back",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0688.gif",
        "id": "0688",
        "name": "scapular pull-up",
        "target": "traps"
    },
    {
        "bodyPart": "cardio",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3219.gif",
        "id": "3219",
        "name": "scissor jumps (male)",
        "target": "cardiovascular system"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1390.gif",
        "id": "1390",
        "name": "seated calf stretch (male)",
        "target": "calves"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1424.gif",
        "id": "1424",
        "name": "seated glute stretch",
        "target": "glutes"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0689.gif",
        "id": "0689",
        "name": "seated leg raise",
        "target": "abs"
    },
    {
        "bodyPart": "back",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0690.gif",
        "id": "0690",
        "name": "seated lower back stretch",
        "target": "lats"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/2567.gif",
        "id": "2567",
        "name": "seated piriformis stretch",
        "target": "glutes"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0691.gif",
        "id": "0691",
        "name": "seated side crunch (wall)",
        "target": "abs"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1587.gif",
        "id": "1587",
        "name": "seated wide angle pose sequence",
        "target": "hamstrings"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0697.gif",
        "id": "0697",
        "name": "self assisted inverse leg curl",
        "target": "hamstrings"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1766.gif",
        "id": "1766",
        "name": "self assisted inverse leg curl",
        "target": "hamstrings"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0696.gif",
        "id": "0696",
        "name": "self assisted inverse leg curl (on floor)",
        "target": "hamstrings"
    },
    {
        "bodyPart": "cardio",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3222.gif",
        "id": "3222",
        "name": "semi squat jump (male)",
        "target": "cardiovascular system"
    },
    {
        "bodyPart": "cardio",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3656.gif",
        "id": "3656",
        "name": "short stride run",
        "target": "cardiovascular system"
    },
    {
        "bodyPart": "back",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1763.gif",
        "id": "1763",
        "name": "shoulder grip pull-up",
        "target": "lats"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3699.gif",
        "id": "3699",
        "name": "shoulder tap",
        "target": "abs"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0699.gif",
        "id": "0699",
        "name": "shoulder tap push-up",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1774.gif",
        "id": "1774",
        "name": "side bridge hip abduction",
        "target": "abductors"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0705.gif",
        "id": "0705",
        "name": "side bridge v. 2",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0709.gif",
        "id": "0709",
        "name": "side hip (on parallel bars)",
        "target": "abs"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0710.gif",
        "id": "0710",
        "name": "side hip abduction",
        "target": "abductors"
    },
    {
        "bodyPart": "back",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1358.gif",
        "id": "1358",
        "name": "side lying floor stretch",
        "target": "lats"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3667.gif",
        "id": "3667",
        "name": "side lying hip adduction (male)",
        "target": "adductors"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1775.gif",
        "id": "1775",
        "name": "side plank hip adduction",
        "target": "adductors"
    },
    {
        "bodyPart": "neck",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0716.gif",
        "id": "0716",
        "name": "side push neck stretch",
        "target": "levator scapulae"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0717.gif",
        "id": "0717",
        "name": "side push-up",
        "target": "triceps"
    },
    {
        "bodyPart": "lower arms",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0721.gif",
        "id": "0721",
        "name": "side wrist pull stretch",
        "target": "forearms"
    },
    {
        "bodyPart": "back",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0720.gif",
        "id": "0720",
        "name": "side-to-side chin",
        "target": "lats"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3213.gif",
        "id": "3213",
        "name": "side-to-side toe touch (male)",
        "target": "abs"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0725.gif",
        "id": "0725",
        "name": "single arm push-up",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3645.gif",
        "id": "3645",
        "name": "single leg bridge with outstretched leg",
        "target": "glutes"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0727.gif",
        "id": "0727",
        "name": "single leg calf raise (on a dumbbell)",
        "target": "calves"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0730.gif",
        "id": "0730",
        "name": "single leg platform slide",
        "target": "hamstrings"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1759.gif",
        "id": "1759",
        "name": "single leg squat (pistol) male",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1489.gif",
        "id": "1489",
        "name": "sissy squat",
        "target": "quads"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0735.gif",
        "id": "0735",
        "name": "sit-up v. 2",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3679.gif",
        "id": "3679",
        "name": "sit-up with arms on chest",
        "target": "abs"
    },
    {
        "bodyPart": "cardio",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3361.gif",
        "id": "3361",
        "name": "skater hops",
        "target": "cardiovascular system"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "skierg machine",
        "gifUrl": "/assets/gifs/2142.gif",
        "id": "2142",
        "name": "ski ergometer",
        "target": "triceps"
    },
    {
        "bodyPart": "cardio",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3671.gif",
        "id": "3671",
        "name": "ski step",
        "target": "cardiovascular system"
    },
    {
        "bodyPart": "back",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3304.gif",
        "id": "3304",
        "name": "skin the cat",
        "target": "upper back"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "sled machine",
        "gifUrl": "/assets/gifs/1425.gif",
        "id": "1425",
        "name": "sled 45 degrees one leg press",
        "target": "glutes"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "sled machine",
        "gifUrl": "/assets/gifs/0738.gif",
        "id": "0738",
        "name": "sled 45в° calf press",
        "target": "calves"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "sled machine",
        "gifUrl": "/assets/gifs/0739.gif",
        "id": "0739",
        "name": "sled 45в° leg press",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "sled machine",
        "gifUrl": "/assets/gifs/1464.gif",
        "id": "1464",
        "name": "sled 45в° leg press (back pov)",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "sled machine",
        "gifUrl": "/assets/gifs/1463.gif",
        "id": "1463",
        "name": "sled 45в° leg press (side pov)",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "sled machine",
        "gifUrl": "/assets/gifs/0740.gif",
        "id": "0740",
        "name": "sled 45в° leg wide press",
        "target": "glutes"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "sled machine",
        "gifUrl": "/assets/gifs/1391.gif",
        "id": "1391",
        "name": "sled calf press on leg press",
        "target": "calves"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "sled machine",
        "gifUrl": "/assets/gifs/0741.gif",
        "id": "0741",
        "name": "sled closer hack squat",
        "target": "glutes"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "sled machine",
        "gifUrl": "/assets/gifs/0742.gif",
        "id": "0742",
        "name": "sled forward angled calf raise",
        "target": "calves"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "sled machine",
        "gifUrl": "/assets/gifs/0743.gif",
        "id": "0743",
        "name": "sled hack squat",
        "target": "glutes"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "sled machine",
        "gifUrl": "/assets/gifs/2334.gif",
        "id": "2334",
        "name": "sled lying calf press",
        "target": "calves"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "sled machine",
        "gifUrl": "/assets/gifs/0744.gif",
        "id": "0744",
        "name": "sled lying squat",
        "target": "glutes"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "sled machine",
        "gifUrl": "/assets/gifs/1392.gif",
        "id": "1392",
        "name": "sled one leg calf press on leg press",
        "target": "calves"
    },
    {
        "bodyPart": "waist",
        "equipment": "hammer",
        "gifUrl": "/assets/gifs/1496.gif",
        "id": "1496",
        "name": "sledge hammer",
        "target": "abs"
    },
    {
        "bodyPart": "back",
        "equipment": "smith machine",
        "gifUrl": "/assets/gifs/0746.gif",
        "id": "0746",
        "name": "smith back shrug",
        "target": "traps"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "smith machine",
        "gifUrl": "/assets/gifs/0747.gif",
        "id": "0747",
        "name": "smith behind neck press",
        "target": "delts"
    },
    {
        "bodyPart": "chest",
        "equipment": "smith machine",
        "gifUrl": "/assets/gifs/0748.gif",
        "id": "0748",
        "name": "smith bench press",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "smith machine",
        "gifUrl": "/assets/gifs/0749.gif",
        "id": "0749",
        "name": "smith bent knee good morning",
        "target": "glutes"
    },
    {
        "bodyPart": "back",
        "equipment": "smith machine",
        "gifUrl": "/assets/gifs/1359.gif",
        "id": "1359",
        "name": "smith bent over row",
        "target": "upper back"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "smith machine",
        "gifUrl": "/assets/gifs/0750.gif",
        "id": "0750",
        "name": "smith chair squat",
        "target": "quads"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "smith machine",
        "gifUrl": "/assets/gifs/0751.gif",
        "id": "0751",
        "name": "smith close-grip bench press",
        "target": "triceps"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "smith machine",
        "gifUrl": "/assets/gifs/0752.gif",
        "id": "0752",
        "name": "smith deadlift",
        "target": "glutes"
    },
    {
        "bodyPart": "chest",
        "equipment": "smith machine",
        "gifUrl": "/assets/gifs/0753.gif",
        "id": "0753",
        "name": "smith decline bench press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "smith machine",
        "gifUrl": "/assets/gifs/0754.gif",
        "id": "0754",
        "name": "smith decline reverse-grip press",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "smith machine",
        "gifUrl": "/assets/gifs/1433.gif",
        "id": "1433",
        "name": "smith front squat (clean grip)",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "smith machine",
        "gifUrl": "/assets/gifs/3281.gif",
        "id": "3281",
        "name": "smith full squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "smith machine",
        "gifUrl": "/assets/gifs/0755.gif",
        "id": "0755",
        "name": "smith hack squat",
        "target": "glutes"
    },
    {
        "bodyPart": "waist",
        "equipment": "smith machine",
        "gifUrl": "/assets/gifs/0756.gif",
        "id": "0756",
        "name": "smith hip raise",
        "target": "abs"
    },
    {
        "bodyPart": "chest",
        "equipment": "smith machine",
        "gifUrl": "/assets/gifs/0757.gif",
        "id": "0757",
        "name": "smith incline bench press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "smith machine",
        "gifUrl": "/assets/gifs/0758.gif",
        "id": "0758",
        "name": "smith incline reverse-grip press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "smith machine",
        "gifUrl": "/assets/gifs/0759.gif",
        "id": "0759",
        "name": "smith incline shoulder raises",
        "target": "serratus anterior"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "smith machine",
        "gifUrl": "/assets/gifs/0760.gif",
        "id": "0760",
        "name": "smith leg press",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "smith machine",
        "gifUrl": "/assets/gifs/1434.gif",
        "id": "1434",
        "name": "smith low bar squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "smith machine",
        "gifUrl": "/assets/gifs/1683.gif",
        "id": "1683",
        "name": "smith machine bicep curl",
        "target": "biceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "smith machine",
        "gifUrl": "/assets/gifs/1625.gif",
        "id": "1625",
        "name": "smith machine decline close grip bench press",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "smith machine",
        "gifUrl": "/assets/gifs/1752.gif",
        "id": "1752",
        "name": "smith machine incline tricep extension",
        "target": "triceps"
    },
    {
        "bodyPart": "chest",
        "equipment": "smith machine",
        "gifUrl": "/assets/gifs/1626.gif",
        "id": "1626",
        "name": "smith machine reverse decline close grip bench press",
        "target": "pectorals"
    },
    {
        "bodyPart": "back",
        "equipment": "smith machine",
        "gifUrl": "/assets/gifs/0761.gif",
        "id": "0761",
        "name": "smith narrow row",
        "target": "upper back"
    },
    {
        "bodyPart": "back",
        "equipment": "smith machine",
        "gifUrl": "/assets/gifs/1360.gif",
        "id": "1360",
        "name": "smith one arm row",
        "target": "upper back"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "smith machine",
        "gifUrl": "/assets/gifs/1393.gif",
        "id": "1393",
        "name": "smith one leg floor calf raise",
        "target": "calves"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "smith machine",
        "gifUrl": "/assets/gifs/0762.gif",
        "id": "0762",
        "name": "smith rear delt row",
        "target": "delts"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "smith machine",
        "gifUrl": "/assets/gifs/0763.gif",
        "id": "0763",
        "name": "smith reverse calf raises",
        "target": "calves"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "smith machine",
        "gifUrl": "/assets/gifs/1394.gif",
        "id": "1394",
        "name": "smith reverse calf raises",
        "target": "calves"
    },
    {
        "bodyPart": "back",
        "equipment": "smith machine",
        "gifUrl": "/assets/gifs/1361.gif",
        "id": "1361",
        "name": "smith reverse grip bent over row",
        "target": "upper back"
    },
    {
        "bodyPart": "chest",
        "equipment": "smith machine",
        "gifUrl": "/assets/gifs/0764.gif",
        "id": "0764",
        "name": "smith reverse-grip press",
        "target": "pectorals"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "smith machine",
        "gifUrl": "/assets/gifs/1395.gif",
        "id": "1395",
        "name": "smith seated one leg calf raise",
        "target": "calves"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "smith machine",
        "gifUrl": "/assets/gifs/0765.gif",
        "id": "0765",
        "name": "smith seated shoulder press",
        "target": "delts"
    },
    {
        "bodyPart": "lower arms",
        "equipment": "smith machine",
        "gifUrl": "/assets/gifs/1426.gif",
        "id": "1426",
        "name": "smith seated wrist curl",
        "target": "forearms"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "smith machine",
        "gifUrl": "/assets/gifs/0766.gif",
        "id": "0766",
        "name": "smith shoulder press",
        "target": "delts"
    },
    {
        "bodyPart": "back",
        "equipment": "smith machine",
        "gifUrl": "/assets/gifs/0767.gif",
        "id": "0767",
        "name": "smith shrug",
        "target": "traps"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "smith machine",
        "gifUrl": "/assets/gifs/0768.gif",
        "id": "0768",
        "name": "smith single leg split squat",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "smith machine",
        "gifUrl": "/assets/gifs/0769.gif",
        "id": "0769",
        "name": "smith sprint lunge",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "smith machine",
        "gifUrl": "/assets/gifs/0770.gif",
        "id": "0770",
        "name": "smith squat",
        "target": "glutes"
    },
    {
        "bodyPart": "lower arms",
        "equipment": "smith machine",
        "gifUrl": "/assets/gifs/0771.gif",
        "id": "0771",
        "name": "smith standing back wrist curl",
        "target": "forearms"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "smith machine",
        "gifUrl": "/assets/gifs/0772.gif",
        "id": "0772",
        "name": "smith standing behind head military press",
        "target": "delts"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "smith machine",
        "gifUrl": "/assets/gifs/0773.gif",
        "id": "0773",
        "name": "smith standing leg calf raise",
        "target": "calves"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "smith machine",
        "gifUrl": "/assets/gifs/0774.gif",
        "id": "0774",
        "name": "smith standing military press",
        "target": "delts"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "smith machine",
        "gifUrl": "/assets/gifs/3142.gif",
        "id": "3142",
        "name": "smith sumo squat",
        "target": "glutes"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "smith machine",
        "gifUrl": "/assets/gifs/1396.gif",
        "id": "1396",
        "name": "smith toe raise",
        "target": "calves"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "smith machine",
        "gifUrl": "/assets/gifs/0775.gif",
        "id": "0775",
        "name": "smith upright row",
        "target": "delts"
    },
    {
        "bodyPart": "chest",
        "equipment": "smith machine",
        "gifUrl": "/assets/gifs/1308.gif",
        "id": "1308",
        "name": "smith wide grip bench press",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "smith machine",
        "gifUrl": "/assets/gifs/1309.gif",
        "id": "1309",
        "name": "smith wide grip decline bench press",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0776.gif",
        "id": "0776",
        "name": "snatch pull",
        "target": "quads"
    },
    {
        "bodyPart": "waist",
        "equipment": "dumbbell",
        "gifUrl": "/assets/gifs/0777.gif",
        "id": "0777",
        "name": "spell caster",
        "target": "abs"
    },
    {
        "bodyPart": "back",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1362.gif",
        "id": "1362",
        "name": "sphinx",
        "target": "spine"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0778.gif",
        "id": "0778",
        "name": "spider crawl push up",
        "target": "glutes"
    },
    {
        "bodyPart": "back",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1363.gif",
        "id": "1363",
        "name": "spine stretch",
        "target": "spine"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/2329.gif",
        "id": "2329",
        "name": "spine twist",
        "target": "abs"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/2368.gif",
        "id": "2368",
        "name": "split squats",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0786.gif",
        "id": "0786",
        "name": "squat jerk",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "bosu ball",
        "gifUrl": "/assets/gifs/1705.gif",
        "id": "1705",
        "name": "squat on bosu ball",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1685.gif",
        "id": "1685",
        "name": "squat to overhead reach",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1686.gif",
        "id": "1686",
        "name": "squat to overhead reach with twist",
        "target": "quads"
    },
    {
        "bodyPart": "waist",
        "equipment": "stability ball",
        "gifUrl": "/assets/gifs/2297.gif",
        "id": "2297",
        "name": "stability ball crunch (full range hands behind head)",
        "target": "abs"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3291.gif",
        "id": "3291",
        "name": "stalder press",
        "target": "triceps"
    },
    {
        "bodyPart": "back",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3669.gif",
        "id": "3669",
        "name": "standing archer",
        "target": "upper back"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "barbell",
        "gifUrl": "/assets/gifs/0788.gif",
        "id": "0788",
        "name": "standing behind neck press",
        "target": "delts"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1490.gif",
        "id": "1490",
        "name": "standing calf raise (on a staircase)",
        "target": "calves"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1397.gif",
        "id": "1397",
        "name": "standing calves",
        "target": "calves"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1398.gif",
        "id": "1398",
        "name": "standing calves calf stretch",
        "target": "calves"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "rope",
        "gifUrl": "/assets/gifs/1599.gif",
        "id": "1599",
        "name": "standing hamstring and calf stretch with strap",
        "target": "hamstrings"
    },
    {
        "bodyPart": "back",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0794.gif",
        "id": "0794",
        "name": "standing lateral stretch",
        "target": "lats"
    },
    {
        "bodyPart": "back",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1364.gif",
        "id": "1364",
        "name": "standing pelvic tilt",
        "target": "spine"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0795.gif",
        "id": "0795",
        "name": "standing single leg curl",
        "target": "hamstrings"
    },
    {
        "bodyPart": "waist",
        "equipment": "wheel roller",
        "gifUrl": "/assets/gifs/0796.gif",
        "id": "0796",
        "name": "standing wheel rollerout",
        "target": "abs"
    },
    {
        "bodyPart": "cardio",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3223.gif",
        "id": "3223",
        "name": "star jump (male)",
        "target": "cardiovascular system"
    },
    {
        "bodyPart": "cardio",
        "equipment": "stationary bike",
        "gifUrl": "/assets/gifs/2138.gif",
        "id": "2138",
        "name": "stationary bike run v. 3",
        "target": "cardiovascular system"
    },
    {
        "bodyPart": "cardio",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/0798.gif",
        "id": "0798",
        "name": "stationary bike walk",
        "target": "cardiovascular system"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3314.gif",
        "id": "3314",
        "name": "straddle maltese",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3298.gif",
        "id": "3298",
        "name": "straddle planche",
        "target": "abs"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1427.gif",
        "id": "1427",
        "name": "straight leg outer hip abductor",
        "target": "abductors"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0803.gif",
        "id": "0803",
        "name": "superman push-up",
        "target": "pectorals"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0805.gif",
        "id": "0805",
        "name": "suspended abdominal fallout",
        "target": "abs"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0806.gif",
        "id": "0806",
        "name": "suspended push-up",
        "target": "pectorals"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0807.gif",
        "id": "0807",
        "name": "suspended reverse crunch",
        "target": "abs"
    },
    {
        "bodyPart": "back",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0808.gif",
        "id": "0808",
        "name": "suspended row",
        "target": "upper back"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0809.gif",
        "id": "0809",
        "name": "suspended split squat",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3433.gif",
        "id": "3433",
        "name": "swimmer kicks v. 2 (male)",
        "target": "glutes"
    },
    {
        "bodyPart": "cardio",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3318.gif",
        "id": "3318",
        "name": "swing 360",
        "target": "cardiovascular system"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1753.gif",
        "id": "1753",
        "name": "three bench dip",
        "target": "triceps"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "tire",
        "gifUrl": "/assets/gifs/2459.gif",
        "id": "2459",
        "name": "tire flip",
        "target": "glutes"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "trap bar",
        "gifUrl": "/assets/gifs/0811.gif",
        "id": "0811",
        "name": "trap bar deadlift",
        "target": "glutes"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0814.gif",
        "id": "0814",
        "name": "triceps dip",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0812.gif",
        "id": "0812",
        "name": "triceps dip (bench leg)",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0813.gif",
        "id": "0813",
        "name": "triceps dip (between benches)",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0815.gif",
        "id": "0815",
        "name": "triceps dips floor",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0816.gif",
        "id": "0816",
        "name": "triceps press",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0817.gif",
        "id": "0817",
        "name": "triceps stretch",
        "target": "triceps"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0871.gif",
        "id": "0871",
        "name": "tuck crunch",
        "target": "abs"
    },
    {
        "bodyPart": "back",
        "equipment": "cable",
        "gifUrl": "/assets/gifs/0818.gif",
        "id": "0818",
        "name": "twin handle parallel grip lat pulldown",
        "target": "lats"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1466.gif",
        "id": "1466",
        "name": "twist hip lift",
        "target": "glutes"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/2802.gif",
        "id": "2802",
        "name": "twisted leg raise",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/2801.gif",
        "id": "2801",
        "name": "twisted leg raise (female)",
        "target": "abs"
    },
    {
        "bodyPart": "back",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3231.gif",
        "id": "3231",
        "name": "two toe touch (male)",
        "target": "spine"
    },
    {
        "bodyPart": "back",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1365.gif",
        "id": "1365",
        "name": "upper back stretch",
        "target": "upper back"
    },
    {
        "bodyPart": "back",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1366.gif",
        "id": "1366",
        "name": "upward facing dog",
        "target": "spine"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3420.gif",
        "id": "3420",
        "name": "v-sit on floor",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0826.gif",
        "id": "0826",
        "name": "vertical leg raise (on parallel bars)",
        "target": "abs"
    },
    {
        "bodyPart": "cardio",
        "equipment": "elliptical machine",
        "gifUrl": "/assets/gifs/2141.gif",
        "id": "2141",
        "name": "walk elliptical cross trainer",
        "target": "cardiovascular system"
    },
    {
        "bodyPart": "cardio",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3655.gif",
        "id": "3655",
        "name": "walking high knees lunge",
        "target": "cardiovascular system"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1460.gif",
        "id": "1460",
        "name": "walking lunge",
        "target": "glutes"
    },
    {
        "bodyPart": "cardio",
        "equipment": "leverage machine",
        "gifUrl": "/assets/gifs/3666.gif",
        "id": "3666",
        "name": "walking on incline treadmill",
        "target": "cardiovascular system"
    },
    {
        "bodyPart": "cardio",
        "equipment": "stepmill machine",
        "gifUrl": "/assets/gifs/2311.gif",
        "id": "2311",
        "name": "walking on stepmill",
        "target": "cardiovascular system"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "weighted",
        "gifUrl": "/assets/gifs/0830.gif",
        "id": "0830",
        "name": "weighted bench dip",
        "target": "triceps"
    },
    {
        "bodyPart": "back",
        "equipment": "weighted",
        "gifUrl": "/assets/gifs/2987.gif",
        "id": "2987",
        "name": "weighted close grip chin-up on dip cage",
        "target": "lats"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "weighted",
        "gifUrl": "/assets/gifs/3643.gif",
        "id": "3643",
        "name": "weighted cossack squats (male)",
        "target": "glutes"
    },
    {
        "bodyPart": "waist",
        "equipment": "weighted",
        "gifUrl": "/assets/gifs/0832.gif",
        "id": "0832",
        "name": "weighted crunch",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "weighted",
        "gifUrl": "/assets/gifs/3670.gif",
        "id": "3670",
        "name": "weighted decline sit-up",
        "target": "abs"
    },
    {
        "bodyPart": "lower legs",
        "equipment": "weighted",
        "gifUrl": "/assets/gifs/0833.gif",
        "id": "0833",
        "name": "weighted donkey calf raise",
        "target": "calves"
    },
    {
        "bodyPart": "chest",
        "equipment": "weighted",
        "gifUrl": "/assets/gifs/1310.gif",
        "id": "1310",
        "name": "weighted drop push up",
        "target": "pectorals"
    },
    {
        "bodyPart": "waist",
        "equipment": "weighted",
        "gifUrl": "/assets/gifs/2135.gif",
        "id": "2135",
        "name": "weighted front plank",
        "target": "abs"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "weighted",
        "gifUrl": "/assets/gifs/0834.gif",
        "id": "0834",
        "name": "weighted front raise",
        "target": "delts"
    },
    {
        "bodyPart": "waist",
        "equipment": "weighted",
        "gifUrl": "/assets/gifs/0866.gif",
        "id": "0866",
        "name": "weighted hanging leg-hip raise",
        "target": "abs"
    },
    {
        "bodyPart": "back",
        "equipment": "weighted",
        "gifUrl": "/assets/gifs/0835.gif",
        "id": "0835",
        "name": "weighted hyperextension (on stability ball)",
        "target": "spine"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "weighted",
        "gifUrl": "/assets/gifs/3641.gif",
        "id": "3641",
        "name": "weighted kneeling step with swing",
        "target": "delts"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "weighted",
        "gifUrl": "/assets/gifs/3644.gif",
        "id": "3644",
        "name": "weighted lunge with swing",
        "target": "glutes"
    },
    {
        "bodyPart": "back",
        "equipment": "weighted",
        "gifUrl": "/assets/gifs/3286.gif",
        "id": "3286",
        "name": "weighted muscle up",
        "target": "lats"
    },
    {
        "bodyPart": "back",
        "equipment": "weighted",
        "gifUrl": "/assets/gifs/3312.gif",
        "id": "3312",
        "name": "weighted muscle up (on bar)",
        "target": "lats"
    },
    {
        "bodyPart": "back",
        "equipment": "weighted",
        "gifUrl": "/assets/gifs/3290.gif",
        "id": "3290",
        "name": "weighted one hand pull up",
        "target": "lats"
    },
    {
        "bodyPart": "waist",
        "equipment": "weighted",
        "gifUrl": "/assets/gifs/0840.gif",
        "id": "0840",
        "name": "weighted overhead crunch (on stability ball)",
        "target": "abs"
    },
    {
        "bodyPart": "back",
        "equipment": "weighted",
        "gifUrl": "/assets/gifs/0841.gif",
        "id": "0841",
        "name": "weighted pull-up",
        "target": "lats"
    },
    {
        "bodyPart": "shoulders",
        "equipment": "weighted",
        "gifUrl": "/assets/gifs/0844.gif",
        "id": "0844",
        "name": "weighted round arm",
        "target": "delts"
    },
    {
        "bodyPart": "waist",
        "equipment": "weighted",
        "gifUrl": "/assets/gifs/0846.gif",
        "id": "0846",
        "name": "weighted russian twist",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "weighted",
        "gifUrl": "/assets/gifs/0845.gif",
        "id": "0845",
        "name": "weighted russian twist (legs up)",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "weighted",
        "gifUrl": "/assets/gifs/2371.gif",
        "id": "2371",
        "name": "weighted russian twist v. 2",
        "target": "abs"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "medicine ball",
        "gifUrl": "/assets/gifs/0847.gif",
        "id": "0847",
        "name": "weighted seated bicep curl  (on stability ball)",
        "target": "biceps"
    },
    {
        "bodyPart": "waist",
        "equipment": "weighted",
        "gifUrl": "/assets/gifs/0849.gif",
        "id": "0849",
        "name": "weighted seated twist (on stability ball)",
        "target": "abs"
    },
    {
        "bodyPart": "waist",
        "equipment": "weighted",
        "gifUrl": "/assets/gifs/0850.gif",
        "id": "0850",
        "name": "weighted side bend (on stability ball)",
        "target": "abs"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "weighted",
        "gifUrl": "/assets/gifs/0851.gif",
        "id": "0851",
        "name": "weighted sissy squat",
        "target": "quads"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "weighted",
        "gifUrl": "/assets/gifs/0852.gif",
        "id": "0852",
        "name": "weighted squat",
        "target": "glutes"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "weighted",
        "gifUrl": "/assets/gifs/0853.gif",
        "id": "0853",
        "name": "weighted standing curl",
        "target": "biceps"
    },
    {
        "bodyPart": "lower arms",
        "equipment": "weighted",
        "gifUrl": "/assets/gifs/0854.gif",
        "id": "0854",
        "name": "weighted standing hand squeeze",
        "target": "forearms"
    },
    {
        "bodyPart": "chest",
        "equipment": "weighted",
        "gifUrl": "/assets/gifs/3313.gif",
        "id": "3313",
        "name": "weighted straight bar dip",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "weighted",
        "gifUrl": "/assets/gifs/3642.gif",
        "id": "3642",
        "name": "weighted stretch lunge",
        "target": "glutes"
    },
    {
        "bodyPart": "chest",
        "equipment": "weighted",
        "gifUrl": "/assets/gifs/0856.gif",
        "id": "0856",
        "name": "weighted svend press",
        "target": "pectorals"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "weighted",
        "gifUrl": "/assets/gifs/1754.gif",
        "id": "1754",
        "name": "weighted three bench dips",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "weighted",
        "gifUrl": "/assets/gifs/1755.gif",
        "id": "1755",
        "name": "weighted tricep dips",
        "target": "triceps"
    },
    {
        "bodyPart": "upper arms",
        "equipment": "weighted",
        "gifUrl": "/assets/gifs/1767.gif",
        "id": "1767",
        "name": "weighted triceps dip on high parallel bars",
        "target": "triceps"
    },
    {
        "bodyPart": "waist",
        "equipment": "wheel roller",
        "gifUrl": "/assets/gifs/0857.gif",
        "id": "0857",
        "name": "wheel rollerout",
        "target": "abs"
    },
    {
        "bodyPart": "cardio",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/3637.gif",
        "id": "3637",
        "name": "wheel run",
        "target": "cardiovascular system"
    },
    {
        "bodyPart": "back",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1429.gif",
        "id": "1429",
        "name": "wide grip pull-up",
        "target": "lats"
    },
    {
        "bodyPart": "back",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1367.gif",
        "id": "1367",
        "name": "wide grip rear pull-up",
        "target": "lats"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1311.gif",
        "id": "1311",
        "name": "wide hand push up",
        "target": "pectorals"
    },
    {
        "bodyPart": "chest",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/2363.gif",
        "id": "2363",
        "name": "wide-grip chest dip on high parallel bars",
        "target": "pectorals"
    },
    {
        "bodyPart": "waist",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/0858.gif",
        "id": "0858",
        "name": "wind sprints",
        "target": "abs"
    },
    {
        "bodyPart": "upper legs",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1604.gif",
        "id": "1604",
        "name": "world greatest stretch",
        "target": "hamstrings"
    },
    {
        "bodyPart": "lower arms",
        "equipment": "body weight",
        "gifUrl": "/assets/gifs/1428.gif",
        "id": "1428",
        "name": "wrist circles",
        "target": "forearms"
    },
    {
        "bodyPart": "lower arms",
        "equipment": "weighted",
        "gifUrl": "/assets/gifs/0859.gif",
        "id": "0859",
        "name": "wrist rollerer",
        "target": "forearms"
    }
];

export const bodyPartsDataCache = ['back', 'cardio', 'chest', 'lower arms', 'lower legs', 'neck', 'shoulders', 'upper arms', 'upper legs', 'waist'];